import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ApiPut, ApiUpload } from "../../apiCommon/helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../apiCommon/helpers/Toast";
import { KTSVG } from "../../_metronic/helpers";

interface ButtonProps {
  show: any;
  handleClose: any;
  setFormData: any;
  formData: any;
  vendorData: any;
  getApprovalServiceRequest: any;
  listData: any;
  updateSignature: (newValue: string, checkType: number) => void;
  closeBoth: () => void;
}

const SignaturePopup = ({
  show,
  handleClose,
  formData,
  vendorData,
  getApprovalServiceRequest,
  listData,
  updateSignature,
  closeBoth,
}: ButtonProps) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<any>();
  const [uploadedFile, setUploadedFile] = React.useState<any>("");

  const uploadFilelHandler = async (e: any) => {
    let file = e.target.files[0];
    // let fileURL = URL.createObjectURL(file);
    // file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/image/document", formData)
      .then((res: any) => {setUploadedFile(res?.data?.data?.image)
        updateSignature(res?.data?.data?.image, 1)})
      .catch((err: any) => {
        console.log("res_blob", err);
        ErrorToast(err?.message);
      });
  };

  let data: any;
  const onSubmit = async () => {
    handleClose();
    //     const id = formData?.filter((i: any) => (i?.type === 2 || i?.type === 3))
    //     const vendorId = id?.map((i: any) => i?.vendor[0]?._id === vendorData?._id)
    //     if (formData && vendorId[0] === true) {
    //         data = formData[1]?._id
    //     }

    //     const body = {
    //         id: data,
    //         approvalStatus: 2,
    //         comment: "TEST",
    //         eSignature: uploadedFile,
    //         writtenSignature: ''
    //     }

    //  await ApiPut('vendor/approval_request', body)
    //         .then((res) => {
    //             SuccessToast(res?.data?.message)
    //             getApprovalServiceRequest()
    //             handleClose()
    //         })
    //         .catch((err) => ErrorToast(err.message))
  };
  return (
    <div>
      <Modal
        id="kt_modal_create_app"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered mw-600px  e-sign"
        show={show}
        onHide={() => {
          closeBoth();
        }}
        backdrop={true}
        size="sm"
        style={{ left: "10%", width: "600px" }}
      >
        <div className="modal-header">
          <h2> E-Signature</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={handleClose}
          >
            <KTSVG
              className="svg-icon-1 text-black"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body py-lg-7 px-lg-10">
          {/* <div className='d-flex justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
                        </div>
                    </div> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-center mb-5">
              <button
                className="btn btn-sm fw-bold me-5 btn-primary btn-green w-150px me-2 hover-none"
                onClick={() => {
                  handleClose();
                }}
              >
                Approve & Submit
              </button>
            </div>
            <p className="mb-5">
              <span className="text-danger">*</span>Your e-signature is required
              to complete this step.
            </p>
            {/* <p>E-Signature</p> */}
            <div className="d-flex justify-content-center">
              <div>
                <button className="btn btn-sm fw-bold btn-primary btn-green hover-none position-relative">
                  Upload
                  <input
                    type="file"
                    {...register("esignature", { required: true })}
                    className="form-control form-control-solid position-absolute "
                    placeholder=""
                    name="esignature"
                    style={{
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      opacity: 0,
                    }}
                    onChange={(e) => uploadFilelHandler(e)}
                  />
                </button>
                {/* {errors.esignature && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>} */}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SignaturePopup;
