import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuInnerWithSub title={intl.formatMessage({id: 'Overview'})} to='/dashboard2' /> */}
      {/* <MenuItem title='Properties' to='/dashboard' /> */}
      {/* <MenuItem title='Tenancies' to='/tenancies' /> */}
      {/* <MenuItem title='Tenants' to='/tenants' /> */}
      {/* <MenuItem title='Financials' to='/financials' /> */}
      {/* <MenuItem title='Announcements' to='/announcements' /> */}
      {/* <MenuItem title='Facility' to='/facility' /> */}
      {/* <MenuItem title='Vendor Management' to='/vendor-management/serviceproviders' /> */}
      {/* <MenuItem title='Request Management' to='/request-management/quotation' /> */}
      {/* <MenuItem title='Maintenance' to='/maintenance' /> */}
      <MenuItem title='Clients' to='/clients' />
      <MenuItem title='Requests' to='/requests' />
      <MenuItem title='Jobs' to='/jobs' />

      {/* <MenuInnerWithSub title='Tenants' to='/crafted'></MenuInnerWithSub> */}
      {/* <MenuInnerWithSub title='Payments & Rent' to='/mega-menu'>
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
