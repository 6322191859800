import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { MultiSelect } from "react-multi-select-component";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import swal from "sweetalert2";
import moment from "moment";
import RequestModel from "./RequestModel";
import Select from "react-select";
import "./styles/styles.scss";
import "./styles/announcement.scss";
import "./styles/tenancies.scss";
import "./styles/vendor.scss";
import {
  ApiPost,
  ApiGet,
  ApiPut,
  ApiDelete,
} from "../../../apiCommon/helpers/API/ApiData";
import { SuccessToast, ErrorToast } from "../../../apiCommon/helpers/Toast";

import maintenance from "../../../img/maintenanceWhite.png";
import maintenanceJob from "../../../img/maintenanceJobs.png";
import maintenanceService from "../../../img/maintenanceService.png";
import maintenanceReactive from "../../../img/maintenanceReactive.png";
import trashImg from "../../../img/trash.png";
import wrenchImg from "../../../img/wrench.png";
import noData from "../../../img/NoData1.svg";
import starYellow from "../../../img/starnotification.svg";

interface ButtonProps {
  clientId?: any;
  isClient?: any;
}

function Jobs({ clientId, isClient }: ButtonProps) {
  const getClientFilter: any = localStorage.getItem("jobFilters");
  const filters = JSON.parse(getClientFilter);

  const [pageLimit, setPageLimit] = useState<any>(1);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>("requests");
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(25);
  const [requestId, setRequestId] = useState<any>(filters?.requestId ?? "");
  const [jobId, setJobId] = useState<any>(filters?.jobId ?? "");
  const [unitNo, setUnitNo] = useState<any>(filters?.unitNo ?? "");
  const [requestModal, setRequestModal] = useState<any>(false);
  const { state, pathname } = useLocation();
  const [maintenanceCount, setMaintenanceCount] = useState<any>();

  // let filters : any = {}
  // const getSubJobFilter :any = localStorage.getItem('subJobFilters')
  // const subJobfilters = JSON.parse(getClientFilter)

  // useEffect(() => {
  //     if(pathname === '/jobs'){
  //         console.log('/jobs')
  //         const getClientFilter :any = localStorage.getItem('jobFilters')
  //          filters = JSON.parse(getClientFilter)
  //     }
  //     if(clientId){
  //         console.log('client')
  //         const getClientFilter :any = localStorage.getItem('subJobFilters')
  //          filters = JSON.parse(getClientFilter)
  //     }

  //   },[])

  const [sortType, setSortType] = useState<any>("A-Z");
  const [sortOptions, setSortOptions] = useState<any>([
    { value: "A-Z", label: "Alphabetic (A-Z)" },
    { value: "Z-A", label: "Alphabetic (Z-A)" },
    { value: "latest", label: "(Latest - Oldest)" },
    { value: "oldest", label: "(Oldest - Latest)" },
  ]);

  const [mainServiceOptions, setMainServiceOptions] = useState<any>([]);
  const [mainServiceDropdownStatus, setMainServiceDropdownStatus] =
    useState<any>(filters?.mainServiceDropdownStatus ?? []);

  const [urgencyOptions, setUrgencyOptions] = useState<any>([
    { value: 0, label: "Standard" },
    { value: 1, label: "Emergency" },
  ]);
  const [urgencyDropdownStatus, setUrgencyDropdownStatus] = useState<any>(
    filters?.urgencyDropdownStatus ?? []
  );

  const [developmentOptions, setDevelopmentOptions] = useState<any>([]);
  const [developmentDropdownStatus, setDevelopmnetDropdownStatus] =
    useState<any>(filters?.developmentFilter ?? []);

  const [requestStatusOptions, setRequestStatusOptions] = useState<any>([
    { value: 0, label: "Not Started" },
    { value: 1, label: "In Progress" },
    { value: 2, label: "Cancelled" },
    { value: 3, label: "Completed" },
  ]);
  const [requestStatusDropdownStatus, setRequestStatusByDropdownStatus] =
    useState<any>(filters?.requestStatusDropdownStatus ?? []);

  const [clientOptions, setClientOptions] = useState<any>([]);
  const [clientDropdownStatus, setClienteDropdownStatus] = useState<any>(
    filters?.clientFilter ?? []
  );

  const [isLoading, setIsLoading] = useState<any>(false);
  const [requestTabTableData, setRequestTabTableData] = useState<any>([]);
  const [jobTabTableData, setJobTabTableData] = useState<any>([]);
  const [serviceTabTableData, setServiceTabTableData] = useState<any>([]);
  const [checkedValues, setCheckedValues] = useState<any>([]);
  let totalSelected: any = 0;

  useEffect(() => {
    if (pathname === "/jobs") {
      const filters = {
        jobId: jobId,
        unitNo: unitNo,
        requestId: requestId,
        clientFilter: clientDropdownStatus,
        developmentFilter: developmentDropdownStatus,
        mainServiceDropdownStatus: mainServiceDropdownStatus,
        requestStatusDropdownStatus: requestStatusDropdownStatus,
        urgencyDropdownStatus: urgencyDropdownStatus,
      };

      localStorage.setItem("jobFilters", JSON.stringify(filters));
    }
  }, [
    unitNo,
    jobId,
    requestId,
    clientDropdownStatus,
    developmentDropdownStatus,
    requestStatusDropdownStatus,
    mainServiceDropdownStatus,
    urgencyDropdownStatus,
  ]);

  const goToMiantenance = (v: any) => {
    if (!(clientId && isClient)) {
      navigate(`/jobs/job/${v?._id}`);
    }
  };

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1);
  };

  const getAllCount = () => {
    ApiGet("vendor/maintenance_service_request/get_all_count")
      .then((res) => {
        console.log(res?.data?.data);
        setMaintenanceCount(res?.data?.data);
      })
      .catch((err) => ErrorToast(err.message));
  };

  const getDevelopmentForDropdown = async () => {
    await ApiPost(`vendor/development/get`, { serch: "", developmentIds: "" })
      .then((res: any) => {
        let values: any = [];
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          };
        });
        setDevelopmentOptions(values);
      })
      .catch((err: any) => {
        ErrorToast(err?.message);
      });
  };

  const getServicesForDropdown = async () => {
    await ApiGet(`vendor/maintenance_main_service`)
      .then((res: any) => {
        let values: any = [];
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name} - ${val?.maintenance_sub_service.name}`,
            value: `${val?._id}`,
          };
        });
        setMainServiceOptions(values);
      })
      .catch((err: any) => {
        ErrorToast(err?.message);
      });
  };

  const getClientDropdownStatus = async () => {
    await ApiGet(`vendor/corporate`)
      .then((res: any) => {
        let values: any = [];
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.firstName} ${val?.lastName} ${val?.companyName}`,
            value: `${val?.workspaceId}`,
          };
        });
        setClientOptions(values);
      })
      .catch((err: any) => {
        ErrorToast(err?.message);
      });
  };

  // get Maintenance Job Table Data
  const getMaintenanceJobServices = async () => {
    setIsLoading(true);
    setJobTabTableData([]);

    let main: any = [];
    mainServiceDropdownStatus?.map((v: any, ind: any) => {
      main[ind] = v?.value;
    });

    let jobStatus: any = [];
    requestStatusDropdownStatus?.map((v: any, ind: any) => {
      jobStatus[ind] = v?.value;
    });

    let development: any = [];
    developmentDropdownStatus?.map((v: any, ind: any) => {
      development[ind] = v?.value;
    });

    let clientIds: any = [];
    clientDropdownStatus?.map((v: any, ind: any) => {
      clientIds[ind] = v?.value;
    });

    let jobUrgencyIds: any = [];
    urgencyDropdownStatus.map((v: any, ind: any) => {
      jobUrgencyIds[ind] = v?.value;
    });

    let body = {
      page: page,
      limit: limit,
      maintenanceSubServiceIds: [],
      maintenanceMainServiceIds: main,
      searchMaintenanceRequestIds: [],
      maintenanceJobStatus: jobStatus,
      clientWorkspaceIds: isClient && clientId ? [clientId] : clientIds,
      searchJobIds: jobId,
      searchUnitNo: unitNo,
      developmentIds: development,
      searchMaintenanceRequestId: requestId,
      jobUrgencyStatus: jobUrgencyIds,
    };

    await ApiPost("vendor/maintenance_service_job/get", body)
      .then((res) => {
        // console.log(res.data.data.maintenance_service_request_data)
        let values: any = [];
        for (
          let i = 0;
          i < res?.data?.data?.maintenance_service_job_data?.length;
          i++
        ) {
          values[i] = false;
        }
        setCheckedValues(values);
        setJobTabTableData(res?.data?.data?.maintenance_service_job_data);

        setPageLimit(res?.data?.data?.state?.page_limit);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        ErrorToast(err.message);
        if (err?.message === "Your account has been suspended!") {
          window.location.pathname = "/auth";
          localStorage.clear();
        }
      });
  };

  const deleteJobs = () => {
    setIsLoading(true);
    let ids: any = [];

    let i: any = 0;

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = requestTabTableData[ind]?._id;
        i++;
      }
    });

    const body = {
      ids: ids,
    };

    let url: any = `vendor/maintenance_service_job/delete`;

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message);

        getMaintenanceJobServices();
        getAllCount();
        setIsLoading(false);
      })
      .catch((err) => {
        ErrorToast(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDevelopmentForDropdown();
    getClientDropdownStatus();
    getServicesForDropdown();
    getAllCount();
  }, []);

  useEffect(() => {
    getMaintenanceJobServices();
  }, [
    page,
    requestId,
    jobId,
    urgencyDropdownStatus,
    developmentDropdownStatus,
    requestStatusDropdownStatus,
    unitNo,
    clientDropdownStatus,
    mainServiceDropdownStatus,
  ]);

  const searchContainerStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "25px",
  };

  const inputBaseStyle = {
    flex: 1,
    padding: "5px",
  };

  const searchIconStyle = {
    padding: "10px",
    color: "gray",
  };

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: "170px",
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected ? "#c2d8d9" : "",
      ":hover": {
        backgroundColor: "#e0f5f0",
        color: "#000",
      },

      fontWeight: "500",
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: "0.1rem solid #007a59 ",
      marginTop: "0px",
      borderRadius: "0px",
      zIndex: "2",
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: "200px",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: "Italic",
      zIndex: "1",
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      color: " #b5b5c3",
      fontWeight: "500",
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: "#f5f8fa",
      boxShadow: "none",
      color: "white",
      border: "0.1rem solid #bec3cb",
      borderRadius: "2px",
      padding: "0rem",
      height: "35px",
      ":hover": {
        borderColor: "#007a59",
        backgroundColor: "#fff",
      },
      ":focus": {
        borderColor: "#007a59 !important",
        backgroundColor: "#fff",
      },
      "::placeholder": {
        fontStyle: "Italic",
      },
      minHeight: "35px",
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#5e6278",
      fontWeight: "600",
      zIndex: "1",
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: "0px 8px 2px 8px",
    }),
  };

  interface IDefaultItemRendererProps {
    checked: boolean;
    option: any;
    disabled?: boolean;
    onClick: any;
  }

  const DefaultItemRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }: IDefaultItemRendererProps) => (
    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />

      <span>
        {option.label === "Select All" ? (
          "All"
        ) : (
          <div
            className={
              option.value == 0
                ? "tenancy-stats booked-bar ms-5"
                : option.value == 1
                ? "tenancy-stats expiring-bar ms-5"
                : option.value == 2
                ? "tenancy-stats active-bar ms-5"
                : option.value == 3
                ? "tenancy-stats expired-bar ms-5"
                : ""
            }
          >
            {option.value == 0
              ? "Not Started"
              : option.value == 1
              ? "In-Progress"
              : option.value == 2
              ? "Completed"
              : option.value == 3
              ? "Canceled"
              : ""}
          </div>
        )}
      </span>
    </div>
  );

  const DefaultUrgencyRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }: IDefaultItemRendererProps) => (
    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />

      <span>
        {option.label === "Select All" ? (
          "All"
        ) : (
          <div
            className={
              option.value == 0
                ? "status no-st-bar"
                : option.value == 1
                ? "status expired-bar"
                : "status no-st-bar"
            }
          >
            {option.value == 0
              ? "Standard"
              : option.value == 1
              ? "Emergency"
              : "-"}
          </div>
        )}
      </span>
    </div>
  );

  return (
    <>
      <div
        className="mx-auto d-flex gap-5 flex-column p-0 m-0 px-2"
        id="kt_app_main"
      >
        {" "}
        {/* container-xxl */}
        {!clientId && (
          <div className="row">
            <div className="col-12">
              <h1 className="page-heading  m-0">
                <b>Jobs</b>
              </h1>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div
              className="d-flex flex-lg-wrap flex-xl-nowrap"
              style={{ gap: "20px" }}
            >
              <div className="customCard py-5  px-7" style={{ width: "18rem" }}>
                <div className="d-flex justify-content-between">
                  <h4 className="text-gray-500">Total Jobs</h4>
                  <h4 className="fw-bold">
                    {maintenanceCount?.maintenanceServiceJobCount}
                  </h4>
                </div>
                <div className="customSeparator total-bar"></div>
              </div>
              <div
                className="customCard py-5  px-7 "
                style={{ width: "18rem" }}
              >
                <div className="d-flex justify-content-between">
                  <h4 className="text-gray-500">Not Started</h4>
                  <h4 className="fw-bold">
                    {maintenanceCount?.maintenanceRequestJobNotStartedCount}
                  </h4>
                </div>
                <div className="customSeparator booked-bar"></div>
              </div>
              <div
                className="customCard py-5  px-7 "
                style={{ width: "18rem" }}
              >
                <div className="d-flex justify-content-between">
                  <h4 className="text-gray-500">In-Progress</h4>
                  <h4 className="fw-bold">
                    {maintenanceCount?.maintenanceRequestJobInProgressCount}
                  </h4>
                </div>
                <div className="customSeparator expiring-bar"></div>
              </div>
              <div
                className="customCard py-5  px-7 "
                style={{ width: "18rem" }}
              >
                <div className="d-flex justify-content-between">
                  <h4 className="text-gray-500">Complete</h4>
                  <h4 className="fw-bold">
                    {maintenanceCount?.maintenanceRequestJobCompletedCount}
                  </h4>
                </div>
                <div className="customSeparator active-bar"></div>
              </div>
              <div
                className="customCard py-5  px-7 "
                style={{ width: "18rem" }}
              >
                <div className="d-flex justify-content-between">
                  <h4 className="text-gray-500">Cancelled</h4>
                  <h4 className="fw-bold">
                    {maintenanceCount?.maintenanceRequestJobCancelledCount}
                  </h4>
                </div>
                <div className="customSeparator expired-bar "></div>
              </div>
            </div>
          </div>
          <div className="row py-5 gy-3 mx-0 mt-5" style={{ gap: "0px" }}>
            <div className={`d-flex align-items-center px-0  col-md-12`}>
              <div className="xyz me-5 pe-3">
                <label className="head-text" style={{ fontWeight: "600" }}>
                  {" "}
                  Search{" "}
                </label>
              </div>

              <div className="xyz me-4">
                <Paper component="form" style={searchContainerStyle}>
                  <InputBase
                    placeholder="Request ID"
                    style={inputBaseStyle}
                    inputProps={{ "aria-label": "Request ID" }}
                    value={requestId}
                    onChange={(e: any) => setRequestId(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label="Request ID">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className="xyz me-4">
                <Paper component="form" style={searchContainerStyle}>
                  <InputBase
                    placeholder="Job ID"
                    style={inputBaseStyle}
                    inputProps={{ "aria-label": "Job ID" }}
                    value={jobId}
                    onChange={(e: any) => setJobId(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label="Job ID">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              {!(isClient && clientId) && (
                <div className="test property me-4">
                  <div
                    className={`multi-select-container ${
                      clientDropdownStatus.length === 0
                        ? "no-value-custom-style client"
                        : "has-value-custom-style"
                    }`}
                  >
                    <MultiSelect
                      options={clientOptions}
                      value={clientDropdownStatus}
                      onChange={setClienteDropdownStatus}
                      labelledBy="Client"
                      overrideStrings={{
                        allItemsAreSelected: "Client", // Custom text for when all items are selected
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="test property me-4">
                <div
                  className={`multi-select-container ${
                    developmentDropdownStatus.length === 0
                      ? "no-value development"
                      : "has-value"
                  }`}
                >
                  <MultiSelect
                    options={developmentOptions}
                    value={developmentDropdownStatus}
                    onChange={setDevelopmnetDropdownStatus}
                    labelledBy="Development"
                    overrideStrings={{
                      allItemsAreSelected: "Development", // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>

              <div className="xyz me-4">
                <Paper component="form" style={searchContainerStyle}>
                  <InputBase
                    placeholder="Unit No"
                    style={inputBaseStyle}
                    inputProps={{ "aria-label": "Unit No" }}
                    value={unitNo}
                    onChange={(e: any) => setUnitNo(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label="Unit No">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className="test property me-4 ">
                <div
                  className={`multi-select-container ${
                    mainServiceDropdownStatus.length === 0
                      ? "no-value mainService"
                      : "has-value"
                  }`}
                >
                  <MultiSelect
                    options={mainServiceOptions}
                    value={mainServiceDropdownStatus}
                    onChange={setMainServiceDropdownStatus}
                    labelledBy="Service"
                    overrideStrings={{
                      allItemsAreSelected: "Services", // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>

              <div className="test property me-4 ">
                <div
                  className={`multi-select-container ${
                    urgencyDropdownStatus.length === 0
                      ? "no-value urgency"
                      : "has-value"
                  }`}
                >
                  <MultiSelect
                    ItemRenderer={DefaultUrgencyRenderer}
                    options={urgencyOptions}
                    value={urgencyDropdownStatus}
                    onChange={setUrgencyDropdownStatus}
                    labelledBy="Urgency"
                    overrideStrings={{
                      allItemsAreSelected: "Urgency", // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>

              <div className="test property me-4 tst">
                <div
                  className={`multi-select-container ${
                    requestStatusDropdownStatus.length === 0
                      ? "no-value-custom-style requestStatus"
                      : "has-value-custom-style"
                  }`}
                >
                  <MultiSelect
                    ItemRenderer={DefaultItemRenderer}
                    options={requestStatusOptions}
                    value={requestStatusDropdownStatus}
                    onChange={setRequestStatusByDropdownStatus}
                    labelledBy="Request Status"
                    overrideStrings={{
                      allItemsAreSelected: "Request Status", // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {/* job tab table data */}

            <div className="card card-flush py-5  px-7">
              <div
                className="app-main flex-column flex-row-fluid"
                id="------------kt_app_main"
              >
                <div className="table-responsive ">
                  {checkedValues?.length > 0 && (
                    <>
                      {checkedValues?.map((ch: any, ind: any) => {
                        if (ch == true) totalSelected = totalSelected + 1;
                        return <></>;
                      })}
                    </>
                  )}
                  <table
                    className="table align-middle table-row-dashed fs-8 gy-5 "
                    id="kt_ecommerce_sales_table"
                  >
                    <thead className="fs-7">
                      <th className="text-start min-w-25px test">
                        <div className="form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="vacant0"
                            name="none"
                            checked={
                              totalSelected == jobTabTableData?.length &&
                              jobTabTableData?.length != 0
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              let values = [...checkedValues];
                              console.log(e.target.checked);
                              if (e.target.checked) {
                                for (
                                  let i = 0;
                                  i < jobTabTableData?.length;
                                  i++
                                ) {
                                  values[i] = true;
                                }
                              } else {
                                for (
                                  let i = 0;
                                  i < jobTabTableData?.length;
                                  i++
                                ) {
                                  values[i] = false;
                                }
                              }
                              setCheckedValues(values);
                            }}
                          />
                        </div>
                      </th>
                      <th className="text-start min-w-100px ps-0">
                        Request ID
                      </th>
                      <th className="text-start min-w-100px ps-0">Job ID</th>

                      <th className="text-start min-w-125px ps-0">Service</th>
                      <th className="text-start min-w-125px ps-0"></th>
                      {/* <th className='text-start min-w-125px ps-0'>Sub Service</th> */}
                      <th className="text-start min-w-125px ps-5">
                        Development
                      </th>
                      <th className="text-center min-w-125px ps-0">
                        Floor/Cluster
                      </th>
                      <th className="text-start min-w-125px ps-5 ">Unit No</th>
                      <th className="text-center min-w-50px ps-0">Urgency</th>
                      <th className="text-center min-w-100px ps-0 ms-5 me-3">
                        Job Status
                      </th>
                      <th className="text-center min-w-175px ps-0 ">
                        Schedule
                      </th>
                      <th className="text-center min-w-150px ps-0 ms-3">
                        Duration (Days)
                      </th>
                      <th className="text-start min-w-100px ps-0">Job Cost</th>
                      <th className="text-start min-w-125px ps-0">Client</th>
                      <th className="text-start min-w-75px ps-0 ">Rating</th>
                    </thead>
                    <tbody className="fw-semibold text-gray-600">
                      {jobTabTableData?.length ? (
                        jobTabTableData?.map((requestTabItem: any, i: any) => {
                          return (
                            <tr>
                              <td className="text-start ps-0 test">
                                <div className="form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="vacant0"
                                    name="none"
                                    checked={
                                      checkedValues[i] == true ? true : false
                                    }
                                    onChange={(e: any) => {
                                      const values = [...checkedValues];
                                      values[i] = !values[i];
                                      setCheckedValues(values);
                                    }}
                                  />
                                </div>
                              </td>
                              <td
                                data-kt-ecommerce-order-filter="order_id"
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {requestTabItem?.maintenance_service_requests[0]
                                  ?.id != undefined
                                  ? requestTabItem
                                      ?.maintenance_service_requests[0]?.id
                                  : "-"}
                              </td>
                              <td
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.id != undefined
                                  ? requestTabItem?.id
                                  : "-"}
                              </td>

                              <td
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {requestTabItem?.maintenance_service_requests[0]
                                  ?.maintenance_main_service.length > 0
                                  ? requestTabItem
                                      ?.maintenance_service_requests[0]
                                      ?.maintenance_main_service[0]?.name
                                  : "-"}
                              </td>
                              <td
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {requestTabItem?.maintenance_service_requests[0]
                                  ?.maintenance_sub_service.length > 0
                                  ? requestTabItem
                                      ?.maintenance_service_requests[0]
                                      ?.maintenance_sub_service[0]?.name
                                  : "-"}
                              </td>
                              {/* <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.maintenance_service_requests[0]?.maintenance_sub_service.length > 0 ? requestTabItem?.maintenance_service_requests[0]?.maintenance_sub_service[0]?.name : '-'}

                                </td> */}

                              <td
                                className="text-start cursor-pointer ps-5 min-w-125"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.maintenance_service_requests[0]
                                  .community?.[0]?.name
                                  ? requestTabItem?.community?.[0]?.name
                                  : requestTabItem?.building?.[0]?.name
                                  ? requestTabItem?.building?.[0]?.name
                                  : "-"}
                              </td>
                              <td
                                className="text-center cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.cluster?.[0]?.name
                                  ? requestTabItem?.cluster?.[0]?.name
                                  : requestTabItem?.floor?.[0]?.name
                                  ? requestTabItem?.floor?.[0]?.name
                                  : "-"}
                              </td>
                              <td
                                className="text-start cursor-pointer ps-5 "
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.unit?.length > 0
                                  ? requestTabItem?.unit[0]?.unitNo
                                  : "-"}
                              </td>
                              <td
                                className="text-center cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                <div
                                  className={
                                    requestTabItem?.jobUrgency == 0
                                      ? "status no-st-bar"
                                      : requestTabItem?.jobUrgency == 1
                                      ? "status expired-bar"
                                      : "status no-st-bar"
                                  }
                                >
                                  {requestTabItem?.jobUrgency == 0
                                    ? "Standard"
                                    : requestTabItem?.jobUrgency == 1
                                    ? "Emergency"
                                    : "-"}
                                </div>
                              </td>
                              <td
                                className="text-center cursor-pointer ps-0 ms-5 me-5"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {/* {requestTabItem?.requestStatus ?? '-'} */}
                                <div
                                  className={
                                    requestTabItem?.maintenanceJobStatus == 0
                                      ? "tenancy-stats booked-bar ms-5"
                                      : requestTabItem?.maintenanceJobStatus ==
                                        1
                                      ? "tenancy-stats expiring-bar ms-5"
                                      : requestTabItem?.maintenanceJobStatus ==
                                        2
                                      ? "tenancy-stats expired-bar ms-5"
                                      : requestTabItem?.maintenanceJobStatus ==
                                        3
                                      ? "tenancy-stats active-bar ms-5"
                                      : "tenancy-stats  no-st-bar ms-5"
                                  }
                                >
                                  {requestTabItem?.maintenanceJobStatus == 0
                                    ? "Not Started"
                                    : requestTabItem?.maintenanceJobStatus == 1
                                    ? "In-Progress"
                                    : requestTabItem?.maintenanceJobStatus == 2
                                    ? "Canceled"
                                    : requestTabItem?.maintenanceJobStatus == 3
                                    ? "Completed"
                                    : "-"}
                                </div>
                              </td>
                              <td
                                className="text-center cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {/* {requestTabItem?.schedule ?? '-'} */}
                                <tr
                                  className="d-flex align-items-center"
                                  style={{ columnGap: "2px" }}
                                >
                                  {requestTabItem?.schedule ? (
                                    <>
                                      <td className="text-center cursor-pointer ps-0 flex-grow-1">
                                        <p className="p-0 m-0">
                                          {requestTabItem?.schedule?.date
                                            ? `${moment
                                                .utc(
                                                  requestTabItem?.schedule?.date
                                                )
                                                .local()
                                                .format("DD.MM.YYYY")}`
                                            : "-"}
                                        </p>
                                        {requestTabItem?.schedule?.startTime ? (
                                          <p className="p-0 m-0">
                                            {requestTabItem?.schedule?.startTime
                                              ? `${moment
                                                  .utc(
                                                    requestTabItem?.schedule
                                                      ?.startTime
                                                  )
                                                  .local()
                                                  .format("HH:mm")}`
                                              : "-"}{" "}
                                            -{" "}
                                            {requestTabItem?.schedule?.endTime
                                              ? `${moment
                                                  .utc(
                                                    requestTabItem?.schedule
                                                      ?.endTime
                                                  )
                                                  .local()
                                                  .format("HH:mm")}`
                                              : "-"}
                                          </p>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td className="text-center cursor-pointer ps-0 flex-grow-1">
                                        {requestTabItem?.schedule
                                          ?.scheduleStatus === 0 ? (
                                          <span>Not Assign</span>
                                        ) : requestTabItem?.schedule
                                            ?.scheduleStatus === 1 ? (
                                          <span style={{ color: "#0d9e91" }}>
                                            On Time
                                          </span>
                                        ) : requestTabItem?.schedule
                                            ?.scheduleStatus === 2 ? (
                                          <span style={{ color: "#ff9a1e" }}>
                                            Late
                                          </span>
                                        ) : requestTabItem?.schedule
                                            ?.scheduleStatus === 3 ? (
                                          <span style={{ color: "#0d9e91" }}>
                                            No Show
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="flex-grow-1">-</td>
                                      <td className="flex-grow-1">-</td>
                                    </>
                                  )}
                                </tr>
                              </td>
                              <td
                                className="text-center cursor-pointer ps-0 ms-5"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.duration != undefined
                                  ? requestTabItem?.duration
                                  : "-"}
                              </td>

                              <td
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.totalAmount != undefined
                                  ? `AED ${requestTabItem?.totalAmount}`
                                  : " AED-"}
                              </td>
                              <td
                                className="text-start cursor-pointer ps-0"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                {requestTabItem?.corporate?.length > 0
                                  ? `${
                                      requestTabItem?.corporate[0]?.firstName ??
                                      "-"
                                    } ${
                                      requestTabItem?.corporate[0]?.lastName ??
                                      "-"
                                    } ${
                                      requestTabItem?.corporate[0]
                                        ?.companyName ?? "-"
                                    }`
                                  : "-"}
                              </td>
                              <td
                                className="text-start cursor-pointer ps-0 min-w-75px"
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                {" "}
                                <div className="d-flex align-items-center ">
                                  <img
                                    src={starYellow}
                                    height={18}
                                    width={18}
                                    className="me-1"
                                  />
                                  {requestTabItem?.rating != undefined
                                    ? requestTabItem?.rating
                                    : "-"}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            <img src={noData} alt="" width={350} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* pagination start*/}
                <div className="row mb-5">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div className="row mt-5">
                    <div className="col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="kt_ecommerce_sales_table_paginate"
                      >
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel=">"
                          pageClassName="paginate_button page-item +"
                          pageLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          previousClassName="paginate_button page-item previous"
                          nextClassName="paginate_button page-item next"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageLimit}
                          forcePage={page - 1}
                          previousLabel="<"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* pagination end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
