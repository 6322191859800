import React, {useState}  from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { AiFillFileImage, AiFillVideoCamera, AiOutlineUpload ,  AiFillDelete,
    AiFillEdit,
    AiFillCheckCircle,} from 'react-icons/ai';
import { ApiGet, ApiPost, ApiPut, ApiUpload, Bucket, ApiDelete } from '../../apiCommon/helpers/API/ApiData';
import { ErrorToast } from '../../apiCommon/helpers/Toast';
import { KTSVG } from '../../_metronic/helpers';
import moment from 'moment'
import swal from 'sweetalert2'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

interface ButtonProps {
    show: any
    handleClose: any
    propertyList: any
    id: any
    unitNo: any
    mediaPop?: any
  }
  
  const MediaPopUp = ({show, handleClose, propertyList, id, mediaPop, unitNo}: ButtonProps) => {
    const [uploadedFile, setUploadedFile] = React.useState<any>('')
    const [comment, setComment] = React.useState<any>('')
    const [updComment, setUpdComment] = React.useState<any>('')
    const [updCommentID, setUpdCommentID] = React.useState<any>('')
    const [corporateID, setCorporateID] = React.useState<any>('')
    const [idToDelete, setIdToDelete] = React.useState<any>('')
  
    const [media, setMedia] = useState<any>([])
    const [isOpen, setIsOpen] = useState<any>(false)
    const [isEdit, setIsEdit] = useState<any>(false)

    const [index, setIndex] = React.useState(-1)
    const [slides, setSlides] = React.useState<any>([])

    const [mediaPopC, setMediaPopC] = useState<any>()
    let user: any = localStorage.getItem('userData')
    user = JSON.parse(user)
  
    console.log(mediaPop)
  
    const uploadFilelHandler = async (e: any) => {
      let file = e.target.files[0]
      let fileURL = URL.createObjectURL(file)
      file.fileURL = fileURL
      let formData = new FormData()
      formData.append('image', file)
      await ApiUpload('upload/image/document', formData)
        .then((res: any) => setUploadedFile(res?.data?.data?.image))
        .catch((err: any) => {
          ErrorToast(err?.message)
        })
    }
  
    const onSubmit = async () => {
  
      let mediaToUpdate : any = mediaPop.media
      for(let i=0; i<media.length; i++){
      mediaToUpdate[mediaToUpdate.length] = media[i]
      }
      console.log(mediaToUpdate)
  
       console.log(mediaToUpdate)
  
      console.log(media)
      console.log('media ' + media.length)
      if (media.length !== 0) {
        const body = {
          id: id,
          media: mediaToUpdate,
          comment: comment,
          serviceRequestId: id?.serviceRequestId,
          communityId: id?.communityId ? id?.communityId : null,
          clusterId: id?.clusterId ? id?.clusterId : null,
          buildingId: id?.buildingId ? id?.buildingId : null,
          floorId: id?.floorId ? id?.floorId : null,
          unitGroupId: id?.unitGroupId ? id?.unitGroupId : null,
          unitId: id?.unit?._id ? id?.unit?._id : null,
        }
        await ApiPut(`vendor/service_job`, body)
          .then((res) => {
            console.log(res?.data?.data?.media)
            // setMedia(res?.data?.data?.media)
            setMedia([])
            console.log(media)
            handleClose()
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        ErrorToast('Nothing New to Upload!!')
      }
    }
    let fileLists_single: any = []
  
    const onFilesAddedSingle = async (event: any) => {
      console.log(media)
      const files = event.target.files
      console.log(files)
      fileLists_single = Array.from(files)
  
      for (let i = 0; i < fileLists_single.length; i++) {
        // let formData: any = new FormData()
        let data: any = fileLists_single[i]
        // let fileURL: any = URL.createObjectURL(data)
        let filetypes: any = fileLists_single[i].type
        // data.fileURL = fileURL
        // formData.append('image', data)
  
        let file = files[i]
        let fileURL = URL.createObjectURL(file)
        file.fileURL = fileURL
        let formData = new FormData()
        formData.append('image', file)
  
        await ApiUpload(`upload/image/other`, formData)
          .then(async (res) => {
          
            setMedia([...media, res?.data?.data?.image])
  
            // let body: any = {
            //   id: id.jobId,
            //   media: [res?.data?.data?.image],
            // }
            // await ApiPut(`corporate/service_job`, body).then((res) => {})
          })
          .catch((err) => {
            console.log('res_blob', err)
            ErrorToast(err?.message)
          })
      }
    }
  
    const deleteUploadImg = async (e: any, ele: any) => {
      const url = ele.replace(`${Bucket}`, '')
      await ApiPost('upload/delete_file', {url})
        .then((res) => {
          console.log('\nres.data.data', res.data.data)
          setMedia(media.filter((flg: any, j: any) => j !== e))
        })
  
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  
    //  Add Comment
    const uploadComment = async () => {
      // console.log(comment)
      if (comment !== '') {
        const body = {
          corporateId: user._id,
          commentType: 0,
          message: comment,
          serviceJobId: id,
        }
        await ApiPost('vendor/comment', body)
          .then(async (res) => {
            setComment('')
            // console.log('Comment ' + comment)
            await ApiGet(`vendor/service_job/${id}`)
              .then((res) => {
                // console.log('\nres.data.data', res.data.data[0])
                setIsOpen(true)
                setMediaPopC(res.data.data[0])
              })
              .catch((err) => ErrorToast(err.message))
            // handleClose()
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        ErrorToast('No Comment to Add!!!')
      }
    }
  
    // update comment
    const updateComment = async () => {
      const body = {
        corporateId: corporateID,
        commentType: 0,
        message: updComment,
        id: updCommentID,
      }
      await ApiPut('vendor/comment', body)
        .then(async (res) => {
          setIsEdit(false)
  
          await ApiGet(`vendor/service_job/${id}`)
            .then((res) => {
              // console.log('\nres.data.data', res.data.data[0])
              setIsOpen(true)
              setMediaPopC(res.data.data[0])
            })
            .catch((err) => ErrorToast(err.message))
          // handleClose()
        })
        .catch((err) => ErrorToast(err.message))
    }
  
    // delete comment
    const deleteComment = async (val: any) => {
      const body = {
        corporateId: corporateID,
        commentType: 0,
        message: updComment,
        id: val,
      }
      await ApiDelete('vendor/comment/' + val, body)
        .then(async (res) => {
          await ApiGet(`vendor/service_job/${id}`)
            .then((res) => {
              // console.log('\nres.data.data', res.data.data[0])
              setIsOpen(true)
              setMediaPopC(res.data.data[0])
            })
            .catch((err) => ErrorToast(err.message))
          // handleClose()
        })
        .catch((err) => ErrorToast(err.message))
    }
  
    // Delete confirmation popup
    const showDelete = (val1: any, val2: any) => {
      setUpdCommentID(val1)
      setCorporateID(val2)
      swal
        .fire({
          text: 'Are you sure you want to permanently delete this ?',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonColor: '#007a59',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          // cancelButtonColor: "transparent",
          cancelButtonText: 'Cancel',
        })
        .then((res) => {
          if (res.isConfirmed) {
            deleteComment(val1)
          }
        })
    }
  
    React.useEffect(() => {
      console.log('mediapop' + mediaPop)
    }, [])
  
    const userName = mediaPop?.comment?.length
      ? mediaPop?.comment?.[0]?.corporate[0]?.firstName +
        ' ' +
        mediaPop?.comment?.[0]?.corporate[0]?.lastName
      : null
  
    return (
      <div>
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-600px'
          show={show}
          onHide={() => {
            handleClose()
            setIsOpen(false)
            setMedia([])
          }}
          backdrop={true}
          size='sm'
        >
          <div className='modal-body py-lg-10 px-lg-10 pt-lg-7'>
            <div className='d-flex justify-content-between'>
              <h2 className='mx-0 green_color my-1'>Unit # : {unitNo} </h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                <KTSVG
                  className='svg-icon-1 text-black'
                  path='/media/icons/duotune/arrows/arr061.svg'
                />
              </div>
            </div>
            {/* <div className='d-flex justify-content-center mt-5'>
              <button
                className='btn btn-md fw-bold btn-primary btn-green hover-none'
                onClick={onSubmit}
              >
                Update
              </button>
            </div> */}
            <div className='d-flex flex-column justify-content-center p-5 br-5 shadow my-5'>
              <h6 className='mx-0 '>
                <b>Media</b>
              </h6>
              <div
                className='d-flex row align-items-center my-5'
                style={{maxHeight: '210px', overflowY: 'scroll'}}
              >
                {media.concat(mediaPop?.media)?.length
                  ? media.concat(mediaPop?.media)?.map((url: any, i: any) => (
                      <div className='img-sets col-md-3'>
                        <img
                          src={url ? Bucket + url : ''}
                          width='100px'
                          height='100px'
                          className='main_img mb-5 cursor-pointer'
                          alt='image'
                          onClick={() => {
                            let y: any = []
                            for (let i = 0; i < media.concat(mediaPop?.media).length; i++) {
                              y[y.length] = {
                                src: Bucket + media.concat(mediaPop?.media[i]),
                              }
                            }
                            setSlides(y)
                            setIndex(i)
                          }}
                        />
                        {/* <img
                          src={closered}
                          className='closered'
                          width='25px'
                          height='25px'
                          onClick={() => deleteUploadImg(i, url)}
                          alt='image'
                        /> */}
                      </div>
                    ))
                  : null}
              </div>
              {/* <div className='d-flex justify-content-center'>
                <label
                  htmlFor='media'
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3 form-label m-0 upload d-flex gap-2'
                >
                  <img src={upload} /> 
                  Upload
                </label>
  
                <input
                  type='file'
                  hidden
                  id='media'
                  className='form-control form-control-solid'
                  name='passport'
                  onChange={(e: any) => onFilesAddedSingle(e)}
                />
              </div> */}
            </div>
            <div className='shadow p-5 br-5 '>
              <div className='m-0 '>
                <div>
                  <h6 className='m-0 mr-2 min-w-100px mb-2'>
                    <b>Comments</b>
                  </h6>
                  {/* <textarea
                    className='form-control form-control-solid m-0'
                    rows={3}
                    name='comment'
                    placeholder='Enter your comments'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea> */}
                </div>
              </div>
              {isOpen ? (
                <>
                  <ul className='p-1 m-0 pe-0' style={{maxHeight: '250px', overflowY: 'scroll'}}>
                    {mediaPopC?.comment.length
                      ? mediaPopC?.comment.map((lis: any, i: any) => (
                          <li className='py-4 grid_12_col borderBottom'>
                            <div className='d-flex'>
                              {/* <p>{lis._id}</p> */}
                              <p
                                className='p-0 m-0 value_black grid_10_col justify-content-start'
                                style={{width: '90%'}}
                              >
                                <b>
                                  {userName || 'User Name'} -{' '}
                                  {moment(lis?.createdAt).format('DD/MM/YYYY - HH:mm')}
                                </b>
                              </p>
                              {/* <p className='p-0 m-0 value_black grid_2_col justify-content-end'>
                               
                                {isEdit === i ? (
                                  <AiFillCheckCircle
                                    color='#007a59'
                                    fontSize={20}
                                    onClick={() => {
                                      updateComment()
                                    }}
                                    style={{cursor: 'pointer', marginRight: '7px'}}
                                  />
                                ) : (
                                    <AiFillEdit
                                    color='#007a59'
                                    fontSize={20}
                                    onClick={() => {
                                              setUpdComment(lis.message)
                                              setUpdCommentID(lis._id)
                                              setCorporateID(lis.corporate._id)
                                              setIsEdit(i)
                                            }}
                                    style={{cursor: 'pointer'}}
                                  />
                               

                                )}
                                <AiFillDelete
                                  color='#007a59'
                                  fontSize={20}
                                  onClick={() => showDelete(lis._id, lis.corporate._id)}
                                  style={{cursor: 'pointer'}}
                                />
                              </p> */}
                            </div>
                            {/* <p className='p-0 m-0'>{i + 1}</p> */}
                            {/* {isEdit === i ? (
                              <textarea
                                className='form-control form-control-solid m-0'
                                name='comment'
                                placeholder='Enter your comments'
                                value={updComment}
                                onChange={(e) => {
                                  setUpdComment(e.target.value)
                                }}
                              ></textarea>
                            ) : ( */}
                              <p className='p-0 m-0'>{lis.message ? lis.message : '---'}</p>
                            {/* )} */}
                          </li>
                        ))
                      : null}
                  </ul>
                </>
              ) : (
                <>
                  <ul className='p-1 m-0 ' style={{maxHeight: '250px', overflowY: 'scroll'}}>
                    {mediaPop?.comment.length
                      ? mediaPop?.comment.map((lis: any, i: any) => (
                          <li className='py-4 grid_12_col borderBottom'>
                            <div className='d-flex'>
                              {/* <p>{lis._id}</p> */}
                              <p
                                className='p-0 m-0 value_black grid_8_col justify-content-start'
                                style={{width: '90%'}}
                              >
                                <b>
                                  {userName || 'User Name'} -{' '}
                                  {moment(lis?.createdAt).format('DD/MM/YYYY - HH:mm')}
                                </b>
                              </p>
                              {/* <p className='p-0 m-0 value_black grid_4_col justify-content-end d-flex'>
                               
                                {isEdit === i ? (
                                  <AiFillCheckCircle
                                    color='#007a59'
                                    fontSize={20}
                                    onClick={() => {
                                      updateComment()
                                    }}
                                    style={{cursor: 'pointer', marginRight: '7px'}}
                                  />
                                ) : (
                               
                                <AiFillEdit
                                color='#007a59'
                                fontSize={20}
                                onClick={() => {
                                          setUpdComment(lis.message)
                                          setUpdCommentID(lis._id)
                                          setCorporateID(lis.corporate._id)
                                          setIsEdit(i)
                                        }}
                                style={{cursor: 'pointer'}}
                              />
                                )}
  
                                <AiFillDelete
                                  color='#007a59'
                                  fontSize={20}
                                  onClick={() => showDelete(lis._id, lis.corporate._id)}
                                  style={{cursor: 'pointer'}}
                                />
                              </p> */}
                            </div>
                            {/* <p className='p-0 m-0'>{i + 1}</p> */}
  
                            {/* {isEdit === i ? (
                              <textarea
                                className='form-control form-control-solid m-0'
                                name='comment'
                                placeholder='Enter your comments'
                                value={updComment}
                                onChange={(e) => {
                                  setUpdComment(e.target.value)
                                }}
                              ></textarea>
                            ) : ( */}
                              <p className='p-0 m-0'>{lis.message ? lis.message : '---'}</p>
                            {/* )} */}
                          </li>
                        ))
                      : null}
                  </ul>
                </>
              )}
  
              {/* <div className='d-flex justify-content-end mt-5'>
                <button
                  className='btn btn-md fw-bold btn-primary btn-green hover-none'
                  onClick={uploadComment}
                >
                  Add Comment
                </button> 
                        </div>*/}
            </div>
          </div>
        </Modal>

        <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />
      </div>
    )
  }
  
  export default MediaPopUp
  