import React, { useState, useEffect } from 'react'
import moment, { Moment } from 'moment'
import addWhite from '../../../../img/add-white.png'
import { ApiPut, ApiGet } from '../../../../apiCommon/helpers/API/ApiData'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import trashImg from '../../../../img/trash.png'
import { ErrorToast, SuccessToast } from '../../../../apiCommon/helpers/Toast'
import externalTeam from '../../../../img/external-team-green.png'
import scheduleWhite from '../../../../img/schedule-white.png'
import { ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import { useParams } from 'react-router-dom'
import AddReport from './AddReport'
import ViewReport from './ViewReport'
import EditReport from './EditReport'
import EditViewReport from './EditViewReport'
import swal from 'sweetalert2'
import informationGreenImg from '../../../../img/informationGreen.png'
import informationWhiteImg from '../../../../img/informationWhite.png'
import warningGreenImg from '../../../../img/warningGreen.png'
import warningWhiteImg from '../../../../img/warningWhite.png'
import completeGreenImg from '../../../../img/completeGreen.png'
import completeWhite from '../../../../img/completeWhite.png'

interface ButtonProps {
  modalData?: any
  coments?: any
  updateJobReport?: any
  jobReports?: any
}

const JobReportLog = ({ modalData, coments, updateJobReport, jobReports }: ButtonProps) => {
  const { jobId } = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)
  const [jobViewDetailReport, setJobViewDetailReport] = useState<any>([])

  const [jobViewEditReport, setJobViewEditReport] = useState<any>([])
  const [showViewEditReport, setShowViewEditReport] = useState<any>(false)

  const [showAddReport, setShowAddReport] = useState<any>(false)
  const [showViewReport, setShowViewReport] = useState<any>(false)

  const [showEditReport, setShowEditReport] = useState<any>(false)
  const [jobEditReport, setJobEditReport] = useState<any>([])

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('DD.MM.YYYY HH:mm')
  }

  const goToViewReport = (v: any) => {
    console.log('submitType',v?.submitByType)
    // return
    if (Object.keys(v).length !== 0) {
      if (v?.submitByType === 0 && v?.reportStatus !== 1 && v?.replayResponse?.createdAt == null) {
        const filterJobReport = jobReports.filter((jobItem: any) => jobItem.id === v.id)
        setJobEditReport(filterJobReport)
        setShowEditReport(true)
        console.log('EditReport',v)

      }
      if ((v?.submitByType === 0 && v?.replayResponse?.createdAt == null && v?.reportStatus == 1) || (v?.submitByType === 0 && v?.replayResponse?.createdAt !== null && v?.reportStatus !== 1) || (v?.submitByType === 0 && v?.replayResponse?.createdAt !== null && v?.reportStatus == 1)) {
        const filterJobReport = jobReports.filter((jobItem: any) => jobItem.id === v.id)
        setJobViewEditReport(filterJobReport)
        setShowViewEditReport(true)
        console.log('EditViewReport',v)
      }
      if (v?.submitByType === 2) {
        const filterJobReport = jobReports.filter((jobItem: any) => jobItem.id === v.id)
        setJobViewDetailReport(filterJobReport)
        setShowViewReport(true)
        console.log('ViewReport',v)
      }

    }
  }

  // useEffect(() => {
  //   // getJobReports()
  // }, [])

  // const getJobReports = async () => {
  //   const body = {
  //     maintenanceServiceJobId: id,
  //   }
  //   await ApiPost(`vendor/maintenance_service_job_report/get`, body)
  //     .then((res) => {
  //       setJobReports(res?.data?.data?.maintenance_service_job_report_data)
  //       console.log('get data',res)
  //     })
  //     .catch((e) => {
  //       setIsLoading(false)
  //       ErrorToast(e?.message)
  //     })
  // }

  return (
    <>
      {/*  Job Report Log */}
      <div className={'card card-flush py-5 mt-10  px-5 mb-5 mx-7 '}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{ fontWeight: '700' }}>
            {'Job Report Log'}
          </h2>

          <button
            type='button'
            className='btn btn-sm fw-bold px-2 green-submit-btn btn-fit-content ms-auto pe-5'
            onClick={() => {
              setShowAddReport(true)
            }}
          >
            <img src={scheduleWhite} height={20} width={20} className='me-3' />
            Generate Report
          </button>
        </div>
        <h3 className='top ps-0 d-block'>
          <i className=''>
            {' '}
            Submit job specific reports to your handling team and ensure all job milestones are
            documented until completion. Any report submitted by an external handling team will
            require your approval.
          </i>
        </h3>

        <div className='table-responsive mt-4'>
          <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='text-start min-w-25px px-0'>Report Id</th>
                <th className='text-start min-w-100px px-0'></th>
                <th className='text-start min-w-125px px-0'>Report Type</th>
                <th className='text-center min-w-100px px-0'>Status</th>
                <th className='text-start min-w-100px px-0'>Submitted By</th>
                <th className='text-start min-w-100px px-0'>Last Updated</th>
              </tr>
            </thead>

            <tbody className='fw-semibold text-gray-600'>
              {jobReports?.map((rs: any, index: any) => {
                return (
                  <tr>
                    <td className='text-start min-w-25px px-0 cursor-pointer' onClick={() => goToViewReport(rs)}>{rs.id !== undefined ? rs?.id : '-'}</td>
                    <td className='text-start min-w-100px px-0 cursor-pointer' onClick={() => goToViewReport(rs)}>Job Report</td>
                    <td className='text-start min-w-125px  px-0 cursor-pointer' onClick={() => goToViewReport(rs)}>
                      <div className='d-flex align-items-center'>
                        <img className='me-4' width={'22px'} src={rs?.reportType === 1 ? warningGreenImg : rs?.reportType === 2 ? completeGreenImg : rs?.reportType === 3 ? informationGreenImg : '-'} alt="Report Type"  />
                        <span >{rs?.reportType === 1 ? 'Job Incomplete' : rs?.reportType === 2 ? 'Job Completion' : rs?.reportType === 3 ? 'Additional Requirements' : '-'}</span>
                      </div>
                    </td>
                    <td className='text-center px-0 d-flex min-w-100px justify-content-center cursor-pointer' onClick={() => goToViewReport(rs)}>
                      <div
                        className={
                          rs?.reportStatus == 0
                            ? 'tenancy-stats not-saved '
                            : rs?.reportStatus == 1
                              ? 'tenancy-stats requested-st '
                              : rs?.reportStatus == 2
                                ? 'tenancy-stats create-st '
                                : rs?.reportStatus == 3
                                  ? 'tenancy-stats cancelled-st'
                                  : 'tenancy-stats  not-saved'
                        }
                      >
                        {rs?.reportStatus == 0
                          ? 'Not Approved'
                          : rs?.reportStatus == 1
                            ? 'Pending Tenant'
                            : rs?.reportStatus == 2
                              ? 'Approved'
                              : rs?.reportStatus == 3
                                ? 'Rejected'
                                : '-'}
                      </div>
                    </td>
                    <td className='text-start cursor-pointer px-0 min-w-100px' data-order='2022-09-11' onClick={() => goToViewReport(rs)}>
                      <p className='my-0'>
                        {rs?.submitUser != undefined &&
                          `${rs?.submitUser?.firstName} ${rs?.submitUser?.lastName} - `}
                      </p>
                      <p className='my-0'>
                        {rs?.createdAt
                          ? `${moment?.utc(rs?.createdAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}`
                          : '-'}
                      </p>
                    </td>
                    <td className='text-start  cursor-pointer px-0 min-w-100px' data-order='2022-09-11' onClick={() => goToViewReport(rs)} >
                      <p className='my-0'>
                        {rs?.replayUser != undefined &&
                          `${rs?.replayUser?.firstName} ${rs?.replayUser?.lastName} - `}
                      </p>
                      <p className='my-0'>
                        {rs?.updatedAt
                          ? `${moment?.utc(rs?.updatedAt)?.local()?.format('DD.MM.YYYY - hh:mm A')}`
                          : '-'}
                      </p>
                    </td>

                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Job Details Modal */}
      {showAddReport && (
        <AddReport
          show={showAddReport}
          getUpdatedJobReports={() => {
            setShowAddReport(false)
            updateJobReport()
          }}
          jobId={jobId}
          handleClose={() => {
            setShowAddReport(false)
          }}
          updateDetails={(comments: any, attachments: any, ind: any) => { }}
        />
      )}
      {showViewReport && (
        <ViewReport
          show={showViewReport}
          jobViewDetailReport={jobViewDetailReport?.[0] ?? []}
          handleClose={() => {
            setShowViewReport(false)
          }}
          updateDetails={(comments: any, attachments: any, ind: any) => { }}
        />
      )}
      {showEditReport && (
        <EditReport
          show={showEditReport}
          jobReport={jobEditReport?.[0] ?? []}
          getUpdatedJobReports={() => {
            setShowEditReport(false)
            updateJobReport()
          }}
          jobId={jobId}
          handleClose={() => {
            setShowEditReport(false)
          }}
          updateDetails={(comments: any, attachments: any, ind: any) => { }}
        />
      )}
      {showViewEditReport && (
        <EditViewReport
          show={showViewEditReport}
          jobViewDetailReport={jobViewEditReport?.[0] ?? []}
          // getUpdatedJobReports={() => {
          //   setShowAddReport(false)
          //   updateJobReport()
          // }}
          // jobId={id}
          handleClose={() => {
            setShowViewEditReport(false)
          }}
          updateDetails={(comments: any, attachments: any, ind: any) => { }}
        />
      )}
    </>
  )
}

export default JobReportLog
