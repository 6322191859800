import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import addWhite from '../../../../img/add-white.png'
// import './styles.scss'
import { Modal } from 'react-bootstrap'
import uploadWhite from '../../../../img/upload.svg'
import { ApiPost, Bucket } from '../../../../apiCommon/helpers/API/ApiData'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import trashImg from '../../../../img/trash.png'
import informationGreenImg from '../../../../img/informationGreen.png'
import informationWhiteImg from '../../../../img/informationWhite.png'
import warningGreenImg from '../../../../img/warningGreen.png'
import warningWhiteImg from '../../../../img/warningWhite.png'
import completeGreenImg from '../../../../img/completeGreen.png'
import completeWhite from '../../../../img/completeWhite.png'
import { ErrorToast, SuccessToast } from '../../../../apiCommon/helpers/Toast'
import submitWhite from '../../../../img/Submit-White.png'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  jobId?: any
  getUpdatedJobReports: any
}

const AddReport = ({ show, handleClose, updateDetails, jobId, getUpdatedJobReports }: ButtonProps) => {
  const format = 'HH:mm'

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [comment, setComment] = useState<any>('')
  const [commentToEdit, setCommentToEdit] = useState<any>('')

  const [attachments, setAttachments] = useState<any>([])
  // const [comments, setComments] = useState<any>([])
  const [commentError, setCommentError] = useState<any>(false)

  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const [selectAdditionalBox, setAdditionalBox] = useState<any>(false)
  const [selectJobIncompleteBox, setJobIncompleteBox] = useState<any>(false)
  const [selectJobCompleteBox, setJobCompleteBox] = useState<any>(false)

  const [boxSelectType, setBoxSelectType] = useState<any>('')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any>(false)



  //
  const handleChange = async (e: any) => {
    e.preventDefault()

    const values = [...attachments]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = e.target.files[i]?.name

        await ApiPost(
          `upload/image/params?fileType=maintenance_service_job_report&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = res?.data?.data?.image
          })
          .catch((err) => ErrorToast(err?.message))
      }
      setAttachments(values)
    }
  }

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('YYYY-MM-DD HH:mm')
  }

  

  const [reportType, setReportType] = useState<any>()
  const [reportTypeOptions, setReportTypeOptions] = useState<any>([
    { value: 3, label: 'Additional Requirements' },
    { value: 2, label: 'Job Completion' },
    { value: 1, label: 'Job Incomplete' },
  ])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '220px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }


  useEffect(() => {
    if (selectAdditionalBox) {
      setBoxSelectType('Additional Box')
      setComment('')
      setAttachments([])
      setCommentError(false)
    }
    else if (selectJobIncompleteBox) {
      setBoxSelectType('Job Incomplete')
      setComment('')
      setAttachments([])
      setCommentError(false)
    }
    else if (selectJobCompleteBox) {
      setBoxSelectType('Job Complete')
      setComment('')
      setAttachments([])
      setCommentError(false)
    } else {
      setBoxSelectType('')
      setComment('')
      setAttachments([])
      setCommentError(false)

    }

  }, [selectAdditionalBox, selectJobIncompleteBox, selectJobCompleteBox])

  const handleSubmit = () => {
    if (!comment) {
      setCommentError(true)
      return
    }
    setIsLoading(true)
   
   
    const currentDateTime = new Date().toUTCString();

    const body = {
      submitByType: 2, // 0(corporate) 1(tenant) 2(vendor)
      // replayByType:0,
      // reportStatus:0,
      maintenanceServiceJobId: jobId,
      reportType: selectAdditionalBox ? 3 : selectJobCompleteBox ? 2 : selectJobIncompleteBox ? 1 : null,
      submitResponse: {
        createdBy: user._id,
        description: comment,
        createdAt: currentDateTime,
        attachments: attachments,
      },
      // replayResponse:{
      //   description:'',
      //   createdAt:'',
      //   createdBy:'',
      //   attachments:[],
      // },
      // maintenanceServiceJobId: jobId
    }
    ApiPost(`vendor/maintenance_service_job_report`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        getUpdatedJobReports()
        // navigate(`/jobs/job/${jobId}`)
        // navigate(-1)
        handleClose()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })

  }

  React.useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);


  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => { handleClose() }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            maxHeight: '600px',
            overflow: 'scroll',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className='head-text pt-5 mb-0'>
              <b>Job Report</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{ fontWeight: '700' }}
              onClick={() => { handleClose() }}
            >
              X
            </label>
          </div>

          <div className='row'>
            <div className='col-12 text-center'>
              <h3 className='head-text pt-5 mb-0'>
                <b>Select Report Type</b>
              </h3>
            </div>
            <div className='row mx-0 mt-4 w-75 mx-auto'>
              <div className='d-flex justify-content-between align-items-start mt-5 pt-5'>
                <div className='text-center d-flex flex-column justify-content-center align-items-center' style={{ width: '100px' }}>
                  <div
                    className={`${selectAdditionalBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                    style={{ width: '100px' }}
                    onClick={() => {
                      setAdditionalBox(!selectAdditionalBox)
                      setJobIncompleteBox(false)
                      setJobCompleteBox(false)
                    }}
                  >
                    <img
                      src={selectAdditionalBox ? informationWhiteImg : informationGreenImg}
                      height={40}
                      width={40}
                      className='d-block'
                    />
                  </div>

                  <h3 className={`head-text mt-3 `}>
                    {!selectAdditionalBox && 'Additional Requirement'}
                    {selectAdditionalBox && <b>Additional Requirement</b>}
                  </h3>
                </div>
                <div className='text-center d-flex flex-column justify-content-center align-items-center' style={{ width: '100px' }}>
                  <div
                    className={`${selectJobIncompleteBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                    style={{ width: '100px' }}
                    onClick={() => {
                      setJobIncompleteBox(!selectJobIncompleteBox)
                      setAdditionalBox(false)
                      setJobCompleteBox(false)
                    }}
                  >
                    <img
                      src={selectJobIncompleteBox ? warningWhiteImg : warningGreenImg}
                      height={40}
                      width={40}
                      className='d-block'
                    />
                  </div>

                  <h3 className={`head-text mt-3 `}>

                    {!selectJobIncompleteBox && 'Job Incomplete'}
                    {selectJobIncompleteBox && <b>Job Incomplete</b>}
                  </h3>
                </div>
                <div className='text-center d-flex flex-column justify-content-center align-items-center ' style={{ width: '100px' }}>
                  <div
                    className={`${selectJobCompleteBox ? 'requestBoxColor' : 'requestBox'} mt-3`}
                    style={{ width: '100px' }}
                    onClick={() => {
                      setJobCompleteBox(!selectJobCompleteBox)
                      setAdditionalBox(false)
                      setJobIncompleteBox(false)
                    }}
                  >
                    <img
                      src={selectJobCompleteBox ? completeWhite : completeGreenImg}
                      height={40}
                      width={40}
                      className='d-block'
                    />
                  </div>

                  <h3 className={`head-text mt-3 `}>
                    {!selectJobCompleteBox && 'Job Complete'}
                    {selectJobCompleteBox && <b>Job Complete</b>}
                  </h3>
                </div>


              </div>
            </div>
          </div>
          {/* 
          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{ borderBottom: '0.1rem solid #bec3cb' }}
          ></div> */}

          {/* <div className='row'>
            <div className='col-12'>
              <div className='d-flex align-items-center justify-content-between my-4 mt-6'>
                <h4 className='my-2' style={{ color: 'black' }}>
                  <label style={{ width: '130px' }} className='labl-gry '>
                    {' '}
                    Report ID
                  </label>
                  <span className='ms-1'>
                    <b>-</b>
                  </span>
                </h4>
                {selectJobCompleteBox && (
                    <div className='d-flex'>
                       <h4 className='me-5'>Status</h4>
                      <div className={ 'tenancy-stats  not-saved '}>
                        {'-'}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            <div className='col-12'>
              <div className='d-flex my-4 mt-6'>
                <h4 className='my-2' style={{ color: 'black' }}>
                  <label style={{ width: '130px' }} className='labl-gry '>
                    {' '}
                    Report Type
                  </label>
                  <span className='ms-1'>
                    <b>{`${boxSelectType}`}</b>
                  </span>
                </h4>
              </div>
            </div>
          </div> */}

          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{ borderBottom: '0.1rem solid #bec3cb' }}
          ></div>

          <div className='row'>
            <div className="col-12 d-flex justify-content-center mt-7">
              <button
                disabled={!(selectAdditionalBox || selectJobCompleteBox || selectJobIncompleteBox) || isLoading}
                type='button'
                className={'btn btn-sm fw-bold green-submit-btn d-flex justify-content-center'}
                onClick={handleSubmit}
              >
                {/* <img src={submitWhite} height={19} width={19} className='me-4' /> {'  '} */}
                {'Submit'}
              </button>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>

              <div
                className={'card card-flush py-5 mt-9 px-5 mb-5 mx-1 '}
                style={{ position: 'relative', zIndex: '10' }}
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <h3 className='head-text'>
                      <b>Report Details</b>
                    </h3>
                  </div>
                </div>

                {/* Comments */}

                <div className='d-flex flex-column mt-10'>
                  <h4 className='top ps-0 head-text'>
                    <b> Your Comments</b>{' '}
                  </h4>
                  <div className='d-flex mt-3'>
                    <textarea
                      disabled={!(selectAdditionalBox || selectJobCompleteBox || selectJobIncompleteBox) || isLoading}
                      className='form-control form-control-solid m-0 mytest'
                      rows={3}
                      name='comment'
                      placeholder='Enter your comments here'
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                  {commentError && <div className='mt-1' style={{ color: '#d54645' }}>
                    Comment is required
                  </div>}
                </div>

                {/* Attachments */}
                <div className='d-flex mt-10'>
                  <div className='d-block'>
                    <h3 className='top ps-0 head-text mb-0'>
                      <b>Attachments</b>
                    </h3>
                    <h3 className='top ps-0 head-text'>
                      <i className=''> (Attach supporting images or videos)</i>
                    </h3>
                  </div>

                  <>
                    <label
                      className='btn btn-sm fw-bold  me-0 blue-btn ps-3 pe-10 ms-auto btn-fit-content'
                      onClick={() => { }}
                      style={{ width: 'fit-content' }}
                      htmlFor='uploadNew'
                    >
                      <img src={uploadWhite} height={18} width={18} className='me-3' />
                      Upload
                    </label>
                    <input
                      disabled={!(selectAdditionalBox || selectJobCompleteBox || selectJobIncompleteBox) || isLoading}
                      type='file'
                      hidden
                      id='uploadNew'
                      className='form-control form-control-solid'
                      placeholder='upload'
                      name='newTenant'
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      multiple
                    />
                  </>
                </div>

                <div className='d-flex flex-wrap mt-5' style={{ maxHeight: '250px', overflow: 'scroll' }}>
                  {attachments?.length > 0 && (
                    <div
                      className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                      style={{ maxHeight: '350px', overflow: 'scroll' }}
                    >
                      {attachments?.map((file: any, ind: any) => {
                        return (
                          <>
                            <div className='imgs-set'>
                              <div className='d-flex mw-350px me-3 '>
                                {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                  <img
                                    src={
                                      file?.split('.')[1] === 'pdf'
                                        ? pdfDocument
                                        : file?.split('.')[1] === 'doc' || file?.split('.')[1] === 'docx'
                                          ? docCocument
                                          : file?.split('.')[1] === 'jpeg' ||
                                            file?.split('.')[1] === 'jpg' ||
                                            file?.split('.')[1] === 'png' ||
                                            file?.split('.')[1] === 'svg' ||
                                            file?.split('.')[1] === 'gif'
                                            ? `${Bucket}${file}`
                                            : fileIcon
                                    }
                                    data-fancybox
                                    data-src={`${Bucket}${file}`}
                                    width='100'
                                    height='100'
                                    className='main_img mb-5'
                                  />
                                {/* </a> */}

                                <img
                                  src={trashImg}
                                  height={15}
                                  width={15}
                                  className='cursor-pointer ms-2 mt-1'
                                  onClick={() => {
                                    const values = [...attachments]
                                    values.splice(ind, 1)
                                    setAttachments(values)
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>

        </div>
      </Modal>
    </div>
  )
}

export default AddReport
