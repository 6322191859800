/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItemWithSub
        to='/clients'
        title='Clients'
        fontIcon='bi-app-indicator'
        // icon='/media/icons/duotune/art/art002.svg'
        hasClient={true}
      >
        <SidebarMenuItem to='/clients' title='Clients' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/requests'
        title='Requests'
        fontIcon='bi-app-indicator'
        // icon='/media/icons/duotune/art/art002.svg'
        hasRequest={true}
      >
        <SidebarMenuItem to='/requests' title='Requests' hasBullet={true} />


      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/jobs'
        title='Jobs'
        fontIcon='bi-app-indicator'
        // icon='/media/icons/duotune/art/art002.svg'
        hasJob={true}
      >
        <SidebarMenuItem to='/jobs' title='Jobs' hasBullet={true} />


      </SidebarMenuItemWithSub>

    </>
  )
}

export { SidebarMenuMain }


// <>
//       <SidebarMenuItemWithSub
//         to='/dashboard'
//         title='Property Management'
//         fontIcon='bi-app-indicator'
//         icon='/media/icons/duotune/art/art002.svg'
//       >
//         <SidebarMenuItemWithSub
//           to='/dashboard'
//           title='Properties'
//           icon='/media/icons/duotune/finance/fin001.svg'
//         >
//           <SidebarMenuItem to='/dashboard' title='Overview' hasBullet={true} />
//           <SidebarMenuItem to='/building-dashboard' title='Buildings' hasBullet={true} />
//           <SidebarMenuItem to='/community-dashboard' title='Communities' hasBullet={true} />
//         </SidebarMenuItemWithSub>
//         <SidebarMenuItemWithSub
//           to='/tenancies'
//           title='Tenancies'
//           icon='/media/icons/duotune/finance/fin001.svg'
//         >
//           <SidebarMenuItem to='/tenancies' title='Tenancies' hasBullet={true} />
//           {/* <SidebarMenuItem to='/building' title='Overview' hasBullet={true} />
//           <SidebarMenuItem to='/building' title='Communities' hasBullet={true} /> */}
//         </SidebarMenuItemWithSub>
//         <SidebarMenuItemWithSub
//           to='/tenants'
//           title='Tenants'
//           icon='/media/icons/duotune/finance/fin001.svg'
//         >
//           <SidebarMenuItem to='/tenants' title='Tenants' hasBullet={true} />
//           {/* <SidebarMenuItem to='/building' title='Overview' hasBullet={true} />
//           <SidebarMenuItem to='/building' title='Communities' hasBullet={true} /> */}
//         </SidebarMenuItemWithSub>
//         <SidebarMenuItemWithSub
//           to='/financials'
//           title='Financials'
//           icon='/media/icons/duotune/finance/fin001.svg'
//         >
//           <SidebarMenuItem to='/financials' title='Financials' hasBullet={true} />
//         </SidebarMenuItemWithSub>
//         <SidebarMenuItemWithSub
//           to='/announcements'
//           title='Announcements'
//           icon='/media/icons/duotune/finance/fin001.svg'
//         >
//           <SidebarMenuItem to='/announcements' title='Announcements' hasBullet={true} />

//         </SidebarMenuItemWithSub>
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItemWithSub
//         to='/dashboard'
//         title='Facility Management'
//         fontIcon='bi-app-indicator'
//         icon='/media/icons/duotune/art/art002.svg'
//       >
//         <SidebarMenuItem to='/facility' title='Facility' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItemWithSub
//         to='/dashboard'
//         title='Vendor Management'
//         fontIcon='bi-app-indicator'
//         icon='/media/icons/duotune/art/art002.svg'
//       >
//         <SidebarMenuItem to='/vendor-management/serviceproviders' title='Vendor Management' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//       <SidebarMenuItemWithSub
//         to='/dashboard'
//         title='Request Management'
//         fontIcon='bi-app-indicator'
//         icon='/media/icons/duotune/art/art002.svg'
//       >
//         <SidebarMenuItem to='/request-management/quotation' title='Request Management' hasBullet={true} />
//       </SidebarMenuItemWithSub>
//     </>
