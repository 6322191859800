/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {getTokenData} from '../../../../apiCommon/helpers/firebase/firebase'
import {Modal} from 'react-bootstrap'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}
// const initialValues = {
//   email: 'admin@demo.com',
//   password: 'demo',
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [firebaseToken, setFirebaseToken] = useState(false)

  // getTokenData(function (token: any) {
  //   setFirebaseToken(token)
  // })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setShowPopup(true)
      try {
        if (firebaseToken) {
          Object.assign(values, {deviceToken: firebaseToken ? firebaseToken : ''})
        } else {
          // alert('Please allow Notification')
        }
        ApiPost('vendor/login', values)
          .then((res) => {
            console.log(res?.data?.data?.token)
            // localStorage.setItem('fb_records', '50')
            localStorage.setItem('userData', JSON.stringify(res?.data?.data))
            const userData  = JSON.parse(localStorage.getItem('userData') ?? '')
            localStorage.setItem('token', userData?.token)
            // localStorage.setItem('token', res?.data?.data?.token)
            // localStorage.setItem(
            //   'permission',
            //   JSON.stringify(res?.data?.data?.corporate_subscription?.accountFeature)
            // )
            // localStorage.setItem(
            //   'roleAndPermissions',
            //   JSON.stringify(res?.data?.data?.roleAndPermission)
            // )
            SuccessToast(res?.data?.message)
            setLoading(false)
            window.location.pathname = '/clients'
          })
          .catch((e) => {
            console.log('e', e)
            ErrorToast(e.message)
            setLoading(false)
          })
      } catch (error) {
        setLoading(false)
      }
    },
  })

  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    // Check if the user has granted permission to show notifications
    if (Notification.permission !== 'granted') {
      setShowPopup(true)
    } else {
      setShowPopup(false)
    }
  }, [])

  const handleGrantPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        setShowPopup(false)
      }
    })
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10 mt-8'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      <Modal open={showPopup}>
        {' '}
        <p>This website would like to send you notifications.</p>
        <button onClick={handleGrantPermission}>Allow</button>
      </Modal>
      {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
