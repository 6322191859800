import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import addWhite from '../../../../img/add-white.png'
// import './styles.scss'
import { Modal } from 'react-bootstrap'
import uploadWhite from '../../../../img/upload.svg'
import { ApiPost, Bucket } from '../../../../apiCommon/helpers/API/ApiData'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import trashImg from '../../../../img/trash.png'
import informationGreenImg from '../../../../img/informationGreen.png'
import informationWhiteImg from '../../../../img/informationWhite.png'
import warningGreenImg from '../../../../img/warningGreen.png'
import warningWhiteImg from '../../../../img/warningWhite.png'
import completeGreenImg from '../../../../img/completeGreen.png'
import completeWhite from '../../../../img/completeWhite.png'
import { ErrorToast } from '../../../../apiCommon/helpers/Toast'
import submitWhite from '../../../../img/Submit-White.png'
import Select from 'react-select'
import starYellow from '../../../../img/starnotification.svg'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

interface ButtonProps {
    show: any
    handleClose: any
    updateDetails?: any
    jobViewDetailReport: any
}

const EditViewReport = ({ show, handleClose, updateDetails, jobViewDetailReport }: ButtonProps) => {
    const format = 'HH:mm'

    const userData: any = localStorage.getItem('userData')
    const user = JSON.parse(userData)

    const [comment, setComment] = useState<any>('')
    const [commentToEdit, setCommentToEdit] = useState<any>('')

    const [attachments, setAttachments] = useState<any>([])
    const [comments, setComments] = useState<any>([])
    const [commentError, setCommentError] = useState<any>(false)

    const [selectedInd, setSelectedInd] = useState<any>(-1)

    const [selectAdditionalBox, setAdditionalBox] = useState<any>(false)
    const [selectJobIncompleteBox, setJobIncompleteBox] = useState<any>(false)
    const [selectJobCompleteBox, setJobCompleteBox] = useState<any>(false)

    const [boxSelectType, setBoxSelectType] = useState<any>('')


    const convertUTCToLocalTime = (utcTimeString: any) => {
        const localDate = moment.utc(utcTimeString).local()
        return localDate.format('YYYY-MM-DD hh:mm A')
    }



    useEffect(() => {
        if (selectAdditionalBox) {
            setBoxSelectType('Additional Box')
        }
        else if (selectJobIncompleteBox) {
            setBoxSelectType('Job Incomplete')
        }
        else if (selectJobCompleteBox) {
            setBoxSelectType('Job Complete')
        } else {
            setBoxSelectType('')

        }

    }, [selectAdditionalBox, selectJobIncompleteBox, selectJobCompleteBox])

    // const [jobReport, setJobReport] = useState<any>()

    React.useEffect(() => {
        fancybox.bind('[data-fancybox]', {
          Toolbar: {
            display: {
              left: ['infobar'],
              middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
              right: ['slideshow', 'thumbs', 'close'],
            },
          },
        })
    
        // return () => {
        //   fancybox.destroy();
        // };
      }, []);

    return (
        <div className='schedule'>
            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
                show={show}
                onHide={() => { handleClose() }}
                backdrop={true}
                size='sm'
            >
                <div
                    className='modal-body py-lg-7 px-lg-7 pt-lg-3'
                    style={{
                        transform: 'translate(0px, -70px)',
                        top: '70px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 20px 0px #516176',
                        maxHeight: '600px',
                        overflow: 'scroll',
                    }}
                >
                    <div className='d-flex align-items-center'>
                        <h2 className='head-text pt-5 mb-0 d-flex align-items-center'>
                            <img width={'35px'} src={jobViewDetailReport?.reportType === 1 ? warningGreenImg : jobViewDetailReport?.reportType === 2 ? completeGreenImg : jobViewDetailReport?.reportType === 3 ? informationGreenImg : '-'} alt="Report Type" className='me-3' />
                            <b>
                                {jobViewDetailReport?.reportType === 1 ? 'Job Incomplete' : jobViewDetailReport?.reportType === 2 ? 'Complete' : jobViewDetailReport?.reportType === 3 ? 'Additional Requirement' : '-'}
                            </b>
                        </h2>

                        <label
                            className='head-text fs-2 cursor-pointer ms-auto'
                            style={{ fontWeight: '700' }}
                            onClick={() => { handleClose() }}
                        >
                            X
                        </label>
                    </div>


                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center my-4 mt-6'>
                                <h4 className='my-2' style={{ color: 'black' }}>
                                    <label style={{ width: '130px' }} className='labl-gry '>
                                        {' '}
                                        Report ID
                                    </label>
                                    <span className='ms-1'>
                                        <b>{jobViewDetailReport?.id ?? '-'}</b>
                                    </span>
                                </h4>

                                {jobViewDetailReport?.reportType === 2 && (
                                    <div className='d-flex '>
                                        <h4 className='me-5'>Status</h4>
                                        <div className={
                                            jobViewDetailReport?.reportStatus === 0
                                                ? 'tenancy-stats not-saved '
                                                : jobViewDetailReport?.reportStatus === 1
                                                    ? 'tenancy-stats requested-st '
                                                    : jobViewDetailReport?.reportStatus === 2
                                                        ? 'tenancy-stats active-bar '
                                                        : jobViewDetailReport?.reportStatus === 3
                                                            ? 'tenancy-stats cancelled-st '
                                                            : 'tenancy-stats  not-saved '
                                        }>
                                            {jobViewDetailReport?.reportStatus === 0
                                                ? 'Not Approved'
                                                : jobViewDetailReport?.reportStatus === 1
                                                    ? 'Pending Tenents'
                                                    : jobViewDetailReport?.reportStatus === 2
                                                        ? 'Approved'
                                                        : jobViewDetailReport?.reportStatus === 3
                                                            ? 'Rejected'
                                                            : '-'}
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between my-4 mt-6'>
                                <h4 className='my-2 d-flex' style={{ color: 'black' }} >
                                    <label style={{ width: '130px' }} className='labl-gry '>
                                        {' '}
                                        Submitted By
                                    </label>
                                    <span className='ms-1'>
                                        <div className='inline-block'> {(jobViewDetailReport?.submitUser?.firstName && jobViewDetailReport?.submitUser?.lastName) ? `${jobViewDetailReport?.submitUser?.firstName} ${jobViewDetailReport?.submitUser?.lastName}` : '-'}</div>
                                        <div>
                                            {jobViewDetailReport?.createdAt
                                                ? `${convertUTCToLocalTime(jobViewDetailReport?.createdAt)}`
                                                : '-'}
                                        </div>

                                    </span>
                                </h4>
                                {(jobViewDetailReport?.reportType === 2 && jobViewDetailReport?.reportStatus !== 1) && <div className='d-flex flex-column'>
                                    <h4 className='head-text'>
                                        {/* {jobViewDetailReport?.replayByType === 1 ? 'Tenant' : jobViewDetailReport?.replayByType === 0 ? `${jobViewDetailReport?.replayUser?.firstName ? jobViewDetailReport?.replayUser?.firstName : '-'} ${jobViewDetailReport?.replayUser?.lastName ? jobViewDetailReport?.replayUser?.lastName : '-'}` : '-'} */}
                                        {jobViewDetailReport?.replayByType === 1 ? 'Tenant' : 
                                        jobViewDetailReport?.replayByType === 0 ? `${jobViewDetailReport?.replayUser?.firstName ? jobViewDetailReport?.replayUser?.firstName : '-'} ${jobViewDetailReport?.replayUser?.lastName ? jobViewDetailReport?.replayUser?.lastName : '-'}`
                                        : jobViewDetailReport?.replayByType === 2 ? `${user?.firstName} ${user?.lastName}` : '-' }
                                    

                                    </h4>
                                    <h4>
                                        {jobViewDetailReport?.replayResponse?.createdAt
                                            ? `${convertUTCToLocalTime(jobViewDetailReport?.replayResponse?.createdAt)}`
                                            : '-'}
                                    </h4>
                                    {(jobViewDetailReport?.reportType === 2 && jobViewDetailReport?.reportStatus === 2) && <div>
                                       <div className='d-flex align-items-center '>
                                       <span>
                                            <b>Rating</b>
                                        </span>
                                        <span className='ms-3'>
                                            <div className='d-flex align-items-center '>
                                                <img src={starYellow} height={18} width={18} className='me-1' />
                                                <b> {jobViewDetailReport?.jobRating != undefined ? jobViewDetailReport?.jobRating : '-'}</b>
                                            </div>
                                        </span>
                                       </div>
                                    </div>}
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div
                        className='d-flex align-items-center mt-3 pb-5 '
                        style={{ borderBottom: '0.1rem solid #bec3cb' }}
                    ></div>

                  

                    {/* submit response */}
                    <div className='row'>
                        <div className='col-12'>
                            <div
                                className={'card card-flush py-5 mt-9 px-5 mb-5 mx-1 '}
                                style={{ position: 'relative', zIndex: '10' }}
                            >

                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h3 className='head-text'>
                                            
                                            <b>Report Details</b>
                                        </h3>
                                    </div>
                                </div>
                                <div>


                                    {/* Comments */}
                                    {(jobViewDetailReport?.submitResponse?.description && jobViewDetailReport?.submitUser) &&
                                        (<div className='d-flex flex-column mt-10'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h4 className='top ps-0 head-text'>
                                                    <b>{jobViewDetailReport?.submitUser != undefined ?
                                                        `${jobViewDetailReport?.submitUser?.firstName} ${jobViewDetailReport?.submitUser?.lastName} - ` : '-'}</b>{' '}
                                                </h4>
                                                <h4>
                                                    {jobViewDetailReport?.submitResponse?.createdAt
                                                        ? `${convertUTCToLocalTime(jobViewDetailReport?.submitResponse?.createdAt)}`
                                                        : '-'}
                                                </h4>
                                            </div>
                                            <div className='d-flex mt-3'>
                                                <p>
                                                    {jobViewDetailReport?.submitResponse?.description ?? '-'}
                                                </p>
                                            </div>
                                        </div>)
                                    }

                                    {/* Attachments */}
                                    {(jobViewDetailReport?.submitResponse?.attachments.length > 0 && jobViewDetailReport?.submitUser) && <div className='d-flex mt-10'>
                                        <div className='d-block'>
                                            <h3 className='top ps-0 head-text mb-0'>
                                                <b>Attachments</b>
                                            </h3>
                                        </div>
                                    </div>}

                                    {(jobViewDetailReport?.submitResponse?.attachments.length > 0 && jobViewDetailReport?.submitUser) &&
                                        (<div className='d-flex flex-wrap mt-5' style={{ maxHeight: '250px', overflow: 'scroll' }}>
                                            {jobViewDetailReport?.submitResponse?.attachments?.length > 0 && (
                                                <div
                                                    className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                                                    style={{ maxHeight: '350px', overflow: 'scroll' }}
                                                >
                                                    {jobViewDetailReport?.submitResponse?.attachments?.map((file: any, ind: any) => {
                                                        return (
                                                            <>
                                                                <div className='imgs-set'>
                                                                    <div className='d-flex mw-350px me-3 '>
                                                                        {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                                                            <img
                                                                                src={
                                                                                    file?.split('.')[1] === 'pdf'
                                                                                        ? pdfDocument
                                                                                        : file?.split('.')[1] === 'doc' || file?.split('.')[1] === 'docx'
                                                                                            ? docCocument
                                                                                            : file?.split('.')[1] === 'jpeg' ||
                                                                                                file?.split('.')[1] === 'jpg' ||
                                                                                                file?.split('.')[1] === 'png' ||
                                                                                                file?.split('.')[1] === 'svg' ||
                                                                                                file?.split('.')[1] === 'gif'
                                                                                                ? `${Bucket}${file}`
                                                                                                : fileIcon
                                                                                }
                                                                                data-fancybox
                                                                                data-src={`${Bucket}${file}`}
                                                                                width='100'
                                                                                height='100'
                                                                                className='main_img mb-5'
                                                                            />
                                                                        {/* </a> */}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>)}
                                </div>

                            </div>
                        </div>
                    </div>

                      {/* replay response */}
                      <div className='row'>
                        <div className='col-12'>
                            <div
                                className={'card card-flush py-5 mt-9 px-5 mb-5 mx-1 '}
                                style={{ position: 'relative', zIndex: '10' }}
                            >
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h3 className='head-text'>
                                        <b>{jobViewDetailReport?.replayByType === 1 ? 'Tenant Feedback':jobViewDetailReport?.replayByType === 2 ? 'Vendor Feedback' : jobViewDetailReport?.replayByType === 0 ? 'Client Feedback' : '-'}</b>
                                        </h3>
                                    </div>
                                </div>

                                {/* Comments */}

                               { <div className='d-flex flex-column mt-10'>
                                    <div className='d-flex justify-content-between align-item-center'>
                                       {jobViewDetailReport?.replayResponse?.description &&  <h4 className='top ps-0 head-text'>
                                            {/* <b>{(jobViewDetailReport?.replayUser?.firstName && jobViewDetailReport?.replayUser?.lastName) ? `${jobViewDetailReport?.replayUser.firstName} ${jobViewDetailReport?.replayUser.lastName}` : '-'}</b>{' '} */}
                                            <b>{jobViewDetailReport?.replayByType === 2 ? `${user?.firstName} ${user?.lastName}` : '-'}</b>

                                        </h4>}
                                        {jobViewDetailReport?.replayResponse?.description && <h4>
                                            {jobViewDetailReport?.replayResponse?.createdAt
                                                ? `${convertUTCToLocalTime(jobViewDetailReport?.replayResponse?.createdAt)}`
                                                : '-'}
                                        </h4>}
                                    </div>
                                    {jobViewDetailReport?.replayResponse?.description && <div className='d-flex mt-3'>
                                        <p>
                                            {jobViewDetailReport?.replayResponse?.description ?? ''}
                                        </p>
                                    </div>}
                                </div>}

                                {/* Attachments */}
                                {jobViewDetailReport?.replayResponse?.attachments?.length > 0 &&<div className='d-flex mt-10'>
                                    <div className='d-block'>
                                        <h3 className='top ps-0 head-text mb-0'>
                                            <b>Attachments</b>
                                        </h3>
                                    </div>
                                </div>}

                                <div className='d-flex flex-wrap mt-5' style={{ maxHeight: '250px', overflow: 'scroll' }}>
                                    {jobViewDetailReport?.replayResponse?.attachments?.length > 0 && (
                                        <div
                                            className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                                            style={{ maxHeight: '350px', overflow: 'scroll' }}
                                        >
                                            {jobViewDetailReport?.replayResponse?.attachments?.map((file: any, ind: any) => {
                                                return (
                                                    <>
                                                        <div className='imgs-set'>
                                                            <div className='d-flex mw-350px me-3 '>
                                                                {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                                                    <img
                                                                        src={
                                                                            file?.split('.')[1] === 'pdf'
                                                                                ? pdfDocument
                                                                                : file?.split('.')[1] === 'doc' || file?.split('.')[1] === 'docx'
                                                                                    ? docCocument
                                                                                    : file?.split('.')[1] === 'jpeg' ||
                                                                                        file?.split('.')[1] === 'jpg' ||
                                                                                        file?.split('.')[1] === 'png' ||
                                                                                        file?.split('.')[1] === 'svg' ||
                                                                                        file?.split('.')[1] === 'gif'
                                                                                        ? `${Bucket}${file}`
                                                                                        : fileIcon
                                                                        }
                                                                        data-fancybox
                                                                        data-src={`${Bucket}${file}`}
                                                                        width='100'
                                                                        height='100'
                                                                        className='main_img mb-5'
                                                                    />
                                                                {/* </a> */}
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default EditViewReport
