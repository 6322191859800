import React, { useEffect, useState, useRef } from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import { MultiSelect } from 'react-multi-select-component'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import swal from 'sweetalert2'
import moment from 'moment'
import RequestModel from './RequestModel'
import Select from 'react-select'
import "./styles/styles.scss"
import "./styles/announcement.scss"
import "./styles/tenancies.scss"
import "./styles/vendor.scss"
import { ApiPost, ApiGet, ApiPut, ApiDelete } from '../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../apiCommon/helpers/Toast'

import maintenance from '../../../img/maintenanceWhite.png'
import maintenanceJob from '../../../img/maintenanceJobs.png'
import maintenanceService from '../../../img/maintenanceService.png'
import maintenanceReactive from '../../../img/maintenanceReactive.png'
import trashImg from '../../../img/trash.png'
import wrenchImg from '../../../img/wrench.png'
import noData from '../../../img/NoData1.svg'
import starYellow from '../../../img/starnotification.svg'

interface ButtonProps {
  clientId?: any
  isClient?: any
}


function Requests({ clientId, isClient }: ButtonProps) {
  const getClientFilter: any = localStorage.getItem('requestFilters')
  const filters = JSON.parse(getClientFilter)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>('requests')
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [requestId, setRequestId] = useState<any>(filters?.requestId ?? '')
  const [jobId, setJobId] = useState<any>('')
  const [unitNo, setUnitNo] = useState<any>('')
  const [requestModal, setRequestModal] = useState<any>(false)
  const { state, pathname } = useLocation()
  const [maintenanceCount, setMaintenanceCount] = useState<any>()




  const [mainServiceOptions, setMainServiceOptions] = useState<any>([])
  const [mainServiceDropdownStatus, setMainServiceDropdownStatus] = useState<any>(filters?.mainServiceDropdownStatus ?? [])


  const [developmentOptions, setDevelopmentOptions] = useState<any>([])
  const [developmentDropdownStatus, setDevelopmnetDropdownStatus] = useState<any>(filters?.developmentFilter ?? [])

  const [requestStatusOptions, setRequestStatusOptions] = useState<any>([
    { value: 0, label: 'Not Started' },
    { value: 1, label: 'In Progress' },
    { value: 2, label: 'Cancelled' },
    { value: 3, label: 'Completed' }
  ])
  const [requestStatusDropdownStatus, setRequestStatusByDropdownStatus] = useState<any>(filters?.requestStatusDropdownStatus ?? [])

  const [clientOptions, setClientOptions] = useState<any>([])
  const [clientDropdownStatus, setClienteDropdownStatus] = useState<any>(filters?.clientFilter ?? [])

  const [isLoading, setIsLoading] = useState<any>(false)
  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  useEffect(() => {
    if (pathname === '/requests') {
      const filters = {
        requestId: requestId,
        clientFilter: clientDropdownStatus,
        developmentFilter: developmentDropdownStatus,
        mainServiceDropdownStatus: mainServiceDropdownStatus,
        requestStatusDropdownStatus: requestStatusDropdownStatus
      }

      localStorage.setItem('requestFilters', JSON.stringify(filters))
    }
  }, [requestId, clientDropdownStatus, developmentDropdownStatus, requestStatusDropdownStatus, mainServiceDropdownStatus])



  const goToMiantenance = (v: any) => {
    navigate(`/request/${v?._id}`)
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)

  }

  const getAllCount = () => {
    ApiGet('vendor/maintenance_service_request/get_all_count')
      .then((res) => {
        setMaintenanceCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getDevelopmentForDropdown = async () => {
    await ApiPost(`vendor/development/get`, { serch: "", developmentIds: '' })
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setDevelopmentOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }


  const getClientDropdownStatus = async () => {
    await ApiGet(`vendor/corporate`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.firstName} ${val?.lastName} ${val?.companyName}`,
            value: `${val?.workspaceId}`,
          }
        })
        setClientOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // get Maintenance Request Table Data
  const getMaintenanceRequestServices = async () => {
    setIsLoading(true)
    setRequestTabTableData([])

    let main: any = []
    mainServiceDropdownStatus?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let development: any = []
    developmentDropdownStatus?.map((v: any, ind: any) => {
      development[ind] = v?.value
    })

    let clientType: any = []
    clientDropdownStatus?.map((v: any, ind: any) => {
      clientType[ind] = v?.value
    })

    let requestStatus: any = []
    requestStatusDropdownStatus?.map((v: any, ind: any) => {
      requestStatus[ind] = v?.value
    })

    let body = {
      page: page,
      limit: limit,
      maintenanceMainServiceIds: main,
      clientWorkspaceIds: (isClient && clientId) ? [clientId] : clientType,
      developmentIds: development,
      maintenanceRequestStatus: requestStatus,
      searchMaintenanceRequestId: requestId,
      maintenanceSubServiceIds: []
    }

    await ApiPost('vendor/maintenance_service_request/get', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_service_request_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setRequestTabTableData(res?.data?.data?.maintenance_service_request_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteRequests = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = requestTabTableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    let url: any = `vendor/maintenance_service_request/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        getMaintenanceRequestServices()
        getAllCount()
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  const getServicesForDropdown = async () => {
    await ApiGet(`vendor/maintenance_main_service`)
      .then((res: any) => {
        let mainValues: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          mainValues[ind] = {
            label: `${val?.name} - ${val?.maintenance_sub_service.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServiceOptions(mainValues)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }





  useEffect(() => {
    getDevelopmentForDropdown()
    getClientDropdownStatus()
    getServicesForDropdown()
    getAllCount()
  }, [])


  useEffect(() => {
    getMaintenanceRequestServices()

  }, [
    page,
    mainServiceDropdownStatus,
    requestId,
    jobId,
    clientDropdownStatus,
    developmentDropdownStatus,
    requestStatusDropdownStatus
  ])


  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }



  interface IDefaultItemRendererProps {
    checked: boolean
    option: any
    disabled?: boolean
    onClick: any
  }

  const DefaultItemRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }: IDefaultItemRendererProps) => (
    <div className={`item-renderer ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />

      <span>{option.label === 'Select All' ? 'All' : <div className={
        option.value == 0
          ? 'tenancy-stats booked-bar ms-5'
          : option.value == 1
            ? 'tenancy-stats expiring-bar ms-5'
            : option.value == 2
              ? 'tenancy-stats active-bar ms-5'
              : option.value == 3
                ? 'tenancy-stats expired-bar ms-5'
                : ''
      }>
        {option.value == 0
          ? 'Not Started'
          : option.value == 1
            ? 'In-Progress'
            : option.value == 2
              ? 'Completed'
              : option.value == 3
                ? 'Canceled'
                : ''}
      </div>}</span>
    </div>
  )



  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        {' '}
        {/* container-xxl */}
        {!clientId && <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Requests</b>
            </h1>
          </div>
        </div>}

        <div className='row'>
          <div className='col-12'>
            <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{ gap: '20px' }}>
              <div className='customCard py-5  px-7' style={{ width: '18rem' }}>
                <div className='d-flex justify-content-between'>
                  <h4 className='text-gray-500'>Total Requests</h4>
                  <h4 className='fw-bold'>{maintenanceCount?.maintenanceServiceRequestCount}</h4>
                </div>
                <div className='customSeparator total-bar'></div>
              </div>
              <div className='customCard py-5  px-7 ' style={{ width: '18rem' }}>
                <div className='d-flex justify-content-between'>
                  <h4 className='text-gray-500'>Not Started</h4>
                  <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestNotStartedCount}</h4>
                </div>
                <div className='customSeparator booked-bar'></div>
              </div>
              <div className='customCard py-5  px-7 ' style={{ width: '18rem' }}>
                <div className='d-flex justify-content-between'>
                  <h4 className='text-gray-500'>In-Progress</h4>
                  <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestInProgressCount}</h4>
                </div>
                <div className='customSeparator expiring-bar'></div>
              </div>
              <div className='customCard py-5  px-7 ' style={{ width: '18rem' }}>
                <div className='d-flex justify-content-between'>
                  <h4 className='text-gray-500'>Complete</h4>
                  <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestCompletedCount}</h4>
                </div>
                <div className='customSeparator active-bar'></div>
              </div>
              <div className='customCard py-5  px-7 ' style={{ width: '18rem' }}>
                <div className='d-flex justify-content-between'>
                  <h4 className='text-gray-500'>Cancelled</h4>
                  <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestCancelledCount}</h4>
                </div>
                <div className='customSeparator expired-bar '></div>
              </div>
            </div>
          </div>
          <div className='row py-5 gy-3 mx-0 mt-5' style={{ gap: '0px' }}>
            <div className={`d-flex align-items-center  px-0 col-md-9`} >
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{ fontWeight: '600' }}>
                  {' '}
                  Search{' '}
                </label>
              </div>



              <div className='xyz me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Request ID'
                    style={inputBaseStyle}
                    inputProps={{ 'aria-label': 'Request ID' }}
                    value={requestId}
                    onChange={(e: any) => setRequestId(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='Request ID'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>



              {!(isClient && clientId) && <div className='test property me-4'>
                <div
                  className={`multi-select-container ${clientDropdownStatus.length === 0 ? 'no-value-custom-style client' : 'has-value-custom-style'
                    }`}
                >
                  <MultiSelect
                    options={clientOptions}
                    value={clientDropdownStatus}
                    onChange={setClienteDropdownStatus}
                    labelledBy='Client'
                    overrideStrings={{
                      allItemsAreSelected: 'Client', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>}

              <div className='test property me-4'>
                <div
                  className={`multi-select-container ${developmentDropdownStatus.length === 0 ? 'no-value development' : 'has-value'
                    }`}
                >
                  <MultiSelect
                    options={developmentOptions}
                    value={developmentDropdownStatus}
                    onChange={setDevelopmnetDropdownStatus}
                    labelledBy='Development'
                    overrideStrings={{
                      allItemsAreSelected: 'Development', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>



              <div className='test property me-4 '>
                <div
                  className={`multi-select-container ${mainServiceDropdownStatus.length === 0 ? 'no-value mainService' : 'has-value'
                    }`}
                >
                  <MultiSelect
                    options={mainServiceOptions}
                    value={mainServiceDropdownStatus}
                    onChange={setMainServiceDropdownStatus}
                    labelledBy='Service'
                    overrideStrings={{
                      allItemsAreSelected: 'Services', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>



              <div className='test property me-4 tst'>
                <div
                  className={`multi-select-container ${requestStatusDropdownStatus.length === 0 ? 'no-value-custom-style requestStatus' : 'has-value-custom-style'
                    }`}
                >
                  <MultiSelect
                    ItemRenderer={DefaultItemRenderer}
                    options={requestStatusOptions}
                    value={requestStatusDropdownStatus}
                    onChange={setRequestStatusByDropdownStatus}
                    labelledBy='Request Status'
                    overrideStrings={{
                      allItemsAreSelected: 'Request Status', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>

            </div>
            <div className='col-md-3 mx-0 d-flex  align-items-center justify-content-end ' style={{ gap: '10px' }}>

              {checkedValues?.length > 0 && (
                <>
                  {checkedValues?.map((ch: any, ind: any) => {
                    if (ch == true) totalSelected = totalSelected + 1
                    return <></>
                  })}
                </>
              )}



              {totalSelected > 0 && (
                <div className='d-flex align-items-center'>
                  <div className='d-flex align-items-center '>
                    <h4 className='my-2' style={{ color: 'black' }}>
                      <label className='labl-gry' >
                        {' '}
                        <i className="text-wrap">Total Selected</i>
                      </label>
                    </h4>
                    <span className='ms-5 ps-1 my-2 me-4'>
                      <b>{totalSelected}</b>
                    </span>
                  </div>
                  <div className=''>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold red-hollow-btn m-0 ps-2'
                      onClick={() => {
                        swal
                          .fire({
                            html: ` 
                                        <div class='fs-3'> <b>Delete Requests
                              }</></div>
                                                <div class='fs-5 mt-4'> Are you sure you want to delete the selected requests
                              }? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          })
                          .then((res: any) => {
                            if (res.isConfirmed) {
                              deleteRequests()
                            }
                          })
                      }}
                      disabled={isLoading}
                    >
                      <img src={trashImg} height={18} width={18} className="me-4" />{' '}
                      Delete
                    </button>
                  </div>

                </div>
              )}

              {/* <button
                  type='button'
                  className='btn btn-sm fw-bold px-2 green-submit-btn w-100'
                  onClick={() => {
                    // navigate('/create-announcement/1')
                    setRequestModal(true)
                  }}
                >
                  <img src={wrenchImg} height={20} width={20} className='me-3' /> Request Maintenance
                </button> */}
            </div>

          </div>
        </div>

      </div>

      <div className='row'>
        <div className='col-12'>

          <div className='card card-flush py-5  px-7'>
            <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
              <div className='table-responsive'>
                <table
                  className='table align-middle table-row-dashed fs-8 gy-5 '
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    <th className='text-start min-w-25px test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={
                            totalSelected == requestTabTableData?.length && requestTabTableData?.length != 0 ? true : false
                          }
                          onChange={(e: any) => {
                            let values = [...checkedValues]
                            console.log(e)
                            if (e.target.checked) {
                              for (let i = 0; i < requestTabTableData?.length; i++) {
                                values[i] = true
                              }
                            } else {
                              for (let i = 0; i < requestTabTableData?.length; i++) {
                                values[i] = false
                              }
                            }
                            setCheckedValues(values)
                          }}
                        />
                      </div>
                    </th>
                    <th className='text-start min-w-100px ps-0'>Request ID</th>
                    <th className='text-start min-w-125px ps-0'>Service</th>
                    <th className='text-start min-w-125px ps-0'></th>
                    {/* <th className='text-start min-w-125px ps-0'></th> */}
                    <th className='text-start min-w-125px ps-5'>Development</th>
                    <th className='text-start min-w-125px ps-0'>Client</th>
                    <th className='text-center min-w-50px ps-0'>Jobs</th>
                    <th className='text-center min-w-125px ps-0 ms-5'>Request Status</th>
                    <th className='text-center min-w-150px ps-0'>Duration (Days)</th>
                    <th className='text-start min-w-175px ps-5'>Submitted By</th>
                    <th className='text-start min-w-125px ps-5'>Request Cost</th>
                    <th className='text-start min-w-75px ps-0 '>Rating</th>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {requestTabTableData?.length > 0 ? (
                      requestTabTableData?.map((requestTabItem: any, i: any) => {
                        return (
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[i] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[i] = !values[i]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start cursor-pointer ps-0'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {requestTabItem?.id != undefined ? requestTabItem?.id : '-'}

                            </td>

                            <td
                              className='text-start cursor-pointer ps-0'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                       
                                  {requestTabItem?.maintenance_main_service.length > 0 ? requestTabItem?.maintenance_main_service[0]?.name : '-'}
                              
                      

                            </td>
                            <td
                              className='text-start cursor-pointer ps-0'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                          
                               {requestTabItem?.maintenance_sub_service.length > 0 ? requestTabItem?.maintenance_sub_service[0]?.name : '-'}

                            </td>

                            {/* <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  

                                </td> */}

                            <td
                              className='text-start cursor-pointer ps-5 min-w-125'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}
                              {requestTabItem?.community?.[0]?.name
                                ? requestTabItem?.community?.[0]?.name
                                : requestTabItem?.building?.[0]?.name ? requestTabItem?.building?.[0]?.name : '-'}

                            </td>
                            <td
                              className='text-start cursor-pointer ps-0 '
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {requestTabItem?.corporate?.length > 0 ? `${requestTabItem?.corporate[0]?.firstName ?? '-'} ${requestTabItem?.corporate[0]?.lastName ?? '-'} ${requestTabItem?.corporate[0]?.companyName ?? '-'}` : '-'}</td>
                            <td
                              className='text-center cursor-pointer ps-0 '
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}
                              <div className='status create-st '>
                                {requestTabItem?.totalJobs != undefined ? requestTabItem?.totalJobs : '-'}
                              </div>
                            </td>
                            <td
                              className='text-center cursor-pointer ps-0 ms-5'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}
                              <div className={
                                    requestTabItem?.maintenanceRequestStatus == 0
                                      ? 'tenancy-stats booked-bar ms-5'
                                      : requestTabItem?.maintenanceRequestStatus == 1
                                        ? 'tenancy-stats expiring-bar ms-5'
                                        : requestTabItem?.maintenanceRequestStatus == 2
                                          ? 'tenancy-stats active-bar ms-5'
                                          : requestTabItem?.maintenanceRequestStatus == 3
                                            ? 'tenancy-stats expired-bar ms-5'
                                            : requestTabItem?.maintenanceRequestStatus == 4
                                            ? 'tenancy-stats req-bar ms-5'
                                            : 'tenancy-stats  no-st-bar ms-5'
                                  }>
                                    {requestTabItem?.maintenanceRequestStatus == 0
                                      ? 'Not Started'
                                      : requestTabItem?.maintenanceRequestStatus == 1
                                        ? 'In-Progress'
                                        : requestTabItem?.maintenanceRequestStatus == 2
                                          ? 'Completed'
                                          : requestTabItem?.maintenanceRequestStatus == 3
                                            ? 'Cancelled'
                                            : requestTabItem?.maintenanceRequestStatus == 4
                                            ? 'Requested'
                                            : '-'}
                          </div>

                            </td>
                            <td
                              className='text-center cursor-pointer ps-0'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}
                              {requestTabItem?.duration != undefined ? requestTabItem?.duration : '-'}
                            </td>

                            <td
                              className='text-start  cursor-pointer ps-5'
                              onClick={() => goToMiantenance(requestTabItem)}
                              data-order='2022-09-11'
                            >
                              <p className='my-0'>
                                {requestTabItem?.submittedBy ? `${requestTabItem?.submittedBy} -` : '-'}

                              </p>
                              <p className='my-0'>
                                {requestTabItem?.updatedAt
                                  ? `${moment?.utc(requestTabItem?.updatedAt)?.local()?.format(
                                    'DD.MM.YYYY - hh:mm A'
                                  )}`
                                  : '-'}
                              </p>
                            </td>


                            <td
                              className='text-start cursor-pointer ps-5'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}
                              {requestTabItem?.totalAmount != undefined ? `AED ${requestTabItem?.totalAmount}` : ' AED-'}

                            </td>
                            <td
                              className='text-start cursor-pointer ps-0 min-w-75px'
                              onClick={() => goToMiantenance(requestTabItem)}
                            >
                              {' '}

                              <div className='d-flex align-items-center '>
                                <img src={starYellow} height={18} width={18} className='me-1' />
                                {requestTabItem?.rating != undefined ? requestTabItem?.rating : '-'}
                              </div>
                            </td>




                            {/* <td
                                className='py-3 ps-0 cursor-pointer ps-0'
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                <p className='my-0'>
                                  {requestTabItem?.updatedBy?.[0] &&
                                   `${requestTabItem?.updatedBy?.[0]?.firstName} ${requestTabItem?.updatedBy?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {requestTabItem?.updatedAt
                                   ? ` ${moment(requestTabItem?.updatedAt).format(
                                     'DD.MM.YYYY hh:mm A'
                                   )}`
                                   : '- -'}
                                </p>
                              </td> */}
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* pagination start*/}
              <div className='row mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                <div className='row mt-5'>
                  <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='kt_ecommerce_sales_table_paginate'
                    >
                      <ReactPaginate
                        breakLabel='...'
                        nextLabel='>'
                        pageClassName='paginate_button page-item +'
                        pageLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        previousClassName='paginate_button page-item previous'
                        nextClassName='paginate_button page-item next'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageLimit}
                        forcePage={page - 1}
                        previousLabel='<'
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* pagination end*/}
            </div>
          </div>


        </div>
      </div>


    </>

  )
}

export default Requests
