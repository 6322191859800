import React, {useEffect, useState} from 'react'
import {Modal, Button, DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
import dayjs, {Dayjs} from 'dayjs'
import redCross from '../../../../img/remove.png'
import addWhite from '../../../../img/add-white.png'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import clock from '../../../../img/three-clock-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  updateData?: any
  modalData?: any
}

const JobStartTime = ({show, handleClose, updateData, modalData}: ButtonProps) => {
  const format = 'HH:mm'

  const {id} = useParams()

  const [selectedDate, setSelectedDate] = useState<any>(
    modalData?.date ? new Date(modalData?.date) : new Date()
  )

  const currentTime = new Date()
  const today = new Date()

  const [startValue, setStartValue] = React.useState<Dayjs | null>(
    modalData?.visitStartTime ? dayjs(modalData?.visitStartTime) : null
  )
  const [endValue, setEndValue] = React.useState<Dayjs | null>(
    modalData?.visitEndTime ? dayjs(modalData?.visitEndTime) : null
  )

  const [isLoading, setIsLoading] = useState<any>(false)

  const [startTimeMinute, setstartTimeMinute] = useState<any>(
    modalData?.visitStartTime ? modalData?.visitStartTime : null
  )
  const [endTimeMinute, setendTimeMinute] = useState<any>(
    modalData?.visitEndTime ? modalData?.visitEndTime : null
  )

  const handleStartValueChange = (value: any) => {
    let temp = new Date(value)

    const utcTime = temp.toISOString()
    let x = temp.toUTCString()
    setStartValue(value)

    // Format the selected time to 'YYYY-MM-DD HH:mm' before saving
    const formattedTime = value ? utcTime : null
    setstartTimeMinute(formattedTime)

    // setstartTimeMinute(isoStr)
  }

  //
  const startVisitSchedule = () => {
    setIsLoading(true)
    const body = {
      visitStartTime: startTimeMinute,
      maintenanceServiceJobScheduleId: modalData?._id,
    }
    ApiPost(`vendor/maintenance_service_job_schedule/visit/start_time`, body)
      .then((res: any) => {
        setIsLoading(false)
        SuccessToast(res?.data?.message)
        updateData()
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  return (
    <div className='schedule'>
      <Modal
        title={[
          <div
            className='d-flex align-items-center pb-4 mt-2'
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <h2 className='pt-0 mb-0'>
              <b>{'Job Start Time'}</b>
            </h2>
          </div>,
        ]}
        visible={show}
        centered
        onCancel={() => handleClose()}
        footer={[
          <div className='d-flex mx-3 justify-content-center mt-0 mb-10'>
            <button
              type='button'
              className='btn btn-sm fw-bold  mx-3 red-hollow-btn status-w-140 ps-2'
              onClick={() => {
                handleClose()
              }}
              disabled={isLoading}
            >
              <img src={redCross} height={18} width={18} className='me-7' /> Cancel
            </button>

            <button
              type='button'
              className={`btn btn-sm fw-bold  me-0 green-submit-btn  ps-2  status-w-140`}
              onClick={() => {startVisitSchedule()}}
              disabled={startTimeMinute == null || isLoading}
            >
              <img src={clock} height={18} width={18} className='me-3' />
              {'Set Start Time'}
            </button>
          </div>,
        ]}
        className='schedule'
      >
        {/*  */}
        <div className='row mx-3'>
          <div className='col-10 px-5  ps-0 my-5 mt-3 mx-auto'>
            <div className='d-flex align-items-center'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className='labl-gry required'>
                  {' '}
                  Start Time
                </label>
              </h4>
              <h4 style={{width: '-webkit-fill-available'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']}>
                    <TimePicker
                      ampm={false}
                      value={startValue}
                      onChange={(newValue) => handleStartValueChange(newValue)}
                      minTime={
                        new Date(selectedDate).getFullYear() === today.getFullYear() &&
                        new Date(selectedDate).getMonth() === today.getMonth() &&
                        new Date(selectedDate).getDate() === today.getDate()
                          ? dayjs(currentTime)
                          : startValue
                      }
                      maxTime={endValue}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </h4>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default JobStartTime
