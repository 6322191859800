import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import addWhite from '../../../../img/add-white.png'
import { ApiPut, ApiGet } from '../../../../apiCommon/helpers/API/ApiData'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import trashImg from '../../../../img/trash.png'
import { ErrorToast, SuccessToast } from '../../../../apiCommon/helpers/Toast'
import externalTeam from '../../../../img/external-team-green.png'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert2'
import userIcon from '../../../../img/green-user-filled.png'
import { CircularProgress } from '@mui/material'

interface ButtonProps {
  modalData?: any
  coments?: any
}

const HandlingTeamComments = ({ modalData, coments }: ButtonProps) => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [comment, setComment] = useState<any>('')
  const [commentToEdit, setCommentToEdit] = useState<any>('')

  const [comments, setComments] = useState<any>(coments)
  const [cooperateUser, setCooperateUser] = useState<any>([])
  const [vendorUser, setVendorUser] = useState<any>([])
  const [commentError, setCommentError] = useState<any>(false)

  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('DD.MM.YYYY HH:mm')
  }

  //
  const updateServiceJob = (data: any) => {
    setIsLoading(true)

    let comm: any = []
    data?.map((dt: any, ind: any) => {
      comm[ind] = dt
      comm[ind].createdBy = dt?.createdBy?._id
    })

    const body = {
      id: id,
      comments: comm,
    }

    ApiPut(`vendor/maintenance_service_job`, body)
      .then((res: any) => {
        
        setIsLoading(false)
        getJobById()
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const getJobById = () => {
    ApiGet(`vendor/maintenance_service_job/${id}`)
      .then((res: any) => {
        console.log(res)
        setCooperateUser(res?.data?.data?.corporate)
        setVendorUser(res?.data?.data?.vendor)
        setComments(res?.data?.data?.comments)
        setIsLoading(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  return (
    <>
      {/* Comments */}
      <div className='card card-flush py-5 mt-5 ms-3 me-0 side mb-0'>
        <div className='row'>
          <div className='col-md-6 col-12'>
            <div className='d-flex px-7'>
              <div className='d-block'>
                <h2 className='top ps-0'>
                  <b>Client Comments</b>{' '}
                </h2>
                <h3 className='top ps-0 d-block'>
                  <i className=''> Include job specific comments shared with the handling team.</i>
                </h3>
              </div>

              {/* <img src={externalTeam} height={45} width={45} className='ms-auto' /> */}
            </div>

            <div className='d-flex mt-3 px-7'>
              <textarea
                className='form-control form-control-solid m-0 mytest'
                rows={3}
                name='comment'
                placeholder='Enter your comments here..'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>

            {commentError && (
              <p style={{ color: 'var(--kt-danger)', gridColumn: '2/2' }} className='m-0 mt-1 px-7'>
                Please enter comment
              </p>
            )}

            <div className='d-flex justify-content-center mt-5  px-7'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10 btn-fit-content'
                onClick={() => {
                  if (comment?.trim()?.length == 0) {
                    setCommentError(true)
                  } else {
                    const userData: any = localStorage.getItem('userData')
                    const user = JSON.parse(userData)

                    let values = [...comments]
                    values[values?.length] = {
                      createdBy: {
                        _id: user?._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                      },
                      createdByType: 1,
                      comment: comment,
                      date: new Date().toISOString(),
                    }
                    setComments(values)
                    setCommentError(false)
                    setComment('')

                    updateServiceJob(values)
                  }
                }}
                style={{ width: 'fit-content' }}
                disabled={isLoading}
              >
                <img src={addWhite} height={17} width={17} className='me-3' /> Add Comment
              </button>
            </div>

            <div className='d-flex my-3 mx-auto  px-7'>
              <h4 className='my-2' style={{ color: 'black' }}>
                <label className=''> Client:</label>
                <span className='ms-1'>
                  {modalData?.maintenance_service_request?.[0]?.handlerType == 0 ? (
                    <b>{'Internal Team'}</b>
                  ) : (
                    <b>
                      {modalData?.maintenance_service_request?.[0]?.vendor?.length > 0
                        ? modalData?.maintenance_service_request?.[0]?.vendor?.[0]?.companyName
                        : '-'}
                    </b>
                  )}
                </span>
              </h4>
            </div>

            <div
              className='d-flex align-items-center mt-3 pb-5 mb-5  mx-3'
              style={{ borderBottom: '0.1rem solid #bec3cb' }}
            ></div>
          </div>
          <div className='col-md-6 col-12'>
            {isLoading ? (<div
              className='d-flex justify-content-center align-items-center w-100'
              style={{ height: '20vh' }}
            >
              <CircularProgress style={{ color: '#007a59' }} />
            </div>) : (<>
              {comments?.length > 0 && (
                <div style={{ maxHeight: '500px', overflow: 'scroll' }} className='px-7'>
                  {comments?.map((comm: any, ind: any) => {
                    return (
                      <>
                        <div className='d-flex mt-4 align-items-center'>
                          {(comm?.createdBy?._id !== user?._id && comm?.createdByType == 0) ? (
                            <div className='d-flex align-items-center'>
                              <img src={userIcon} height={24} width={25} className='me-3' />
                              <h5 className='ps-1 mb-0' style={{ color: '#146c6a' }}>
                                <b>
                                {`${(modalData?.corporate?.[0]?.firstName || modalData?.corporate?.[0]?.lastName) ? `${modalData?.corporate?.[0]?.firstName} ${modalData?.corporate?.[0]?.lastName}` : '-'}`}
                                </b>
                              </h5>
                            </div>
                          ) : (
                            <h5 className='top ps-1 mb-0'>
                              <b>
                              {` ${modalData?.vendor?.[0]?.companyName ? modalData?.vendor?.[0]?.companyName : ''}`}
                              </b>
                            </h5>
                          )}

                          <h5 className='top ps-0 ms-auto mb-0'>
                            {comm?.date ? `${convertUTCToLocalTime(comm?.date)}` : ''}
                          </h5>
                        </div>
                        <div className='d-flex mt-2'>
                          <textarea
                            className={`form-control form-control-solid m-0 ${selectedInd == ind
                              ? 'jobs-comments-edit'
                              : comm?.createdBy?._id !== user?._id
                                ? 'green-border-box '
                                : 'jobs-comments'
                              } `}
                            rows={3}
                            name='comment'
                            placeholder='Enter your comments here'
                            value={selectedInd !== ind ? comm?.comment : commentToEdit}
                            onChange={(e) => setCommentToEdit(e.target.value)}
                            disabled={selectedInd !== ind}
                          ></textarea>
                        </div>

                        <div className='mt-2 mb-8'>
                          {selectedInd == ind ? (
                            <div className='d-flex me-1 mb-2'>
                              <img
                                src={removed}
                                height={18}
                                width={18}
                                className='ms-auto cursor-pointer'
                                onClick={() => {
                                  setSelectedInd(-1)
                                }}
                              />
                              <img
                                src={correct}
                                height={18}
                                width={18}
                                className='ms-3 cursor-pointer'
                                onClick={() => {
                                  let values = [...comments]
                                  values[ind].comment = commentToEdit
                                  setComments(values)
                                  setSelectedInd(-1)

                                  updateServiceJob(values)
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              {(comm?.createdBy?._id == user?._id) &&
                                <div className='me-1 mb-2 text-end'>
                                  <img
                                    src={pencil}
                                    alt=''
                                    height={18}
                                    width={18}
                                    className='ms-4  cursor-pointer'
                                    onClick={() => {
                                      setSelectedInd(ind)
                                      setCommentToEdit(comm?.comment)
                                    }}
                                  />
                                  <img
                                    src={trashImg}
                                    alt=''
                                    height={18}
                                    width={18}
                                    className='ms-3 cursor-pointer'
                                    onClick={() => {
                                      swal
                                        .fire({
                                          html: ` 
                                      <div class='fs-3'> <b>Delete Comment</b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to delete this comment ? </div>`,
                                          // icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#35475e',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          cancelButtonText: 'No',
                                          cancelButtonColor: '#fff',
                                          customClass: {
                                            confirmButton: 'custom-confirm-button',
                                            cancelButton: 'custom-cancel-button',
                                            popup: 'custom-popup',
                                          },
                                          reverseButtons: true,
                                        })
                                        .then((res: any) => {
                                          if (res.isConfirmed) {
                                            let values = [...comments]
                                            values.splice(ind, 1)
                                            setComments(values)

                                            updateServiceJob(values)
                                          }
                                        })
                                    }}
                                  />
                                </div>
                              }
                            </>
                          )}
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </>)}

          </div>
        </div>


      </div>
    </>
  )
}

export default HandlingTeamComments
