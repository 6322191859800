import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Tempate from '../modules/Tempate'
import Maintenance from '../modules/Maintenance/Maintenance'
import Requests from '../modules/Maintenance/Requests'
import Jobs from '../modules/Maintenance/Jobs'
import Clients from '../modules/Maintenance/Clients'
import ClientDetail from '../modules/Maintenance/ClientDetail'
import ViewEditJob from '../modules/Maintenance/ViewEditJob'
import ServiceJob from '../modules/Maintenance/JobDetail/ServiceJob'
import ViewEditRequest from '../modules/MaintenanceCorporate/ViewEditRequest'


const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* <Route path='auth/*' element={<Navigate to='/viewrequest' />} /> */}
        <Route path='auth/*' element={<Navigate to='/clients' />} />
        {/* Pages */}
        <Route path='viewrequest' element={<Tempate />} />
        {/* Lazy Modules */}
        {/* <Route path='maintenance' element={<Maintenance />} /> */}
        <Route path='requests' element={<Requests />} />
        <Route path='jobs' element={<Jobs />} />
        <Route path='clients' element={<Clients />} />
        <Route path='client/:id' element={<ClientDetail />} />
        {/* <Route
          path='/maintenance/job/:id'
          element={
            <SuspensedView>
              <ViewEditJob />
            </SuspensedView>
          }
        /> */}
        
        <Route
          path='/client/:id/job/:jobId'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />
        <Route
          path='/client/:id/request/:requestId'
          element={
            <SuspensedView>
              <ViewEditRequest />
            </SuspensedView>
          }
        />
        <Route
          path='/client/:id/request/:requestId/job/:jobId'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />
        <Route
          path='/client/:id/job/:jobId'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs/job/:jobId'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />

        <Route
          path='/request/:requestId'
          element={
            <SuspensedView>
              <ViewEditRequest />
            </SuspensedView>
          }
        />

          <Route
          path='/request/:requestId/job/:jobId'
          element={
            <SuspensedView>
              <ServiceJob />
            </SuspensedView>
          }
        />
        

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
     
      
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
