import React, { useEffect, useState, useRef } from 'react'
import backArrow from '../../../img/back-arrow.png'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import Requests from './SubRequest'
import Jobs from './SubJob'

const ClientDetail = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const location:any = useLocation()
    const isClient:any = location.state ? location?.state?.key === 'clients' : false
    const clientDetail:any = location?.state?.name 
    const getActiveTab :any = localStorage.getItem('activeTab')
    const tab = JSON.parse(getActiveTab)
    const [activeTab, setActiveTab] = useState<any>(tab ?? 'requests')
 

    useEffect(() => {
        if(id){
            localStorage.setItem('activeTab',JSON.stringify(activeTab))
        }
    },[activeTab])

    return (
        <>
            <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex align-items-center mb-1'>
                            <span
                                className='rounded-circle'
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate('/clients')}
                            >
                                <img src={backArrow} height='14' width='14' />
                            </span>
                            <h2 className='m-0 head-text ms-2'>
                                {clientDetail ? `${clientDetail}` :`Client`}
                            </h2>
                        </div>
                    </div>
                    <div className='col-12 d-flex justify-content-center my-5'>
                        <div className='col-4'>
                            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
                                <li className='nav-item'>
                                    <a
                                        className={`nav-link text-active-primary pb-2 me-5 px-5 ${activeTab === 'requests' ? 'active' : ''
                                            }`}
                                        data-bs-toggle='tab'
                                        onClick={() => {
                                            setActiveTab('requests')

                                        }}
                                    >
                                        Requests
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <a
                                        className={`nav-link text-active-primary pb-2 me-5 px-5 ${activeTab !== 'requests' ? 'active' : ''
                                            }`}
                                        data-bs-toggle='tab'
                                        onClick={() => {
                                            setActiveTab('jobs')

                                        }}
                                    >
                                        Jobs
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {activeTab === 'requests' && (<Requests isClient={isClient ? 'clients' : ''} clientId={isClient ? location?.state?.workspaceId : ''}/>)}
                </div>
                <div className="row">
                    {activeTab === 'jobs' && (<Jobs isClient={isClient ? 'clients' : ''} clientId={isClient ? location?.state?.workspaceId : ''}/>)}
                </div>

            </div>
        </>
    )
}

export default ClientDetail