import React, { useEffect, useState, useRef } from 'react'
import { MultiSelect } from 'react-multi-select-component'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import "./styles/styles.scss"
import "./styles/announcement.scss"
import "./styles/tenancies.scss"
import "./styles/vendor.scss"
import { ApiPost, ApiGet, ApiPut, ApiDelete } from '../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../apiCommon/helpers/Toast'

import trashImg from '../../../img/trash.png'
import noData from '../../../img/NoData1.svg'
import starYellow from '../../../img/starnotification.svg'



function Clients() {
  const [pageLimit, setPageLimit] = useState<any>(1)
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>('requests')
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const { state , pathname} = useLocation()
  const [maintenanceCount, setMaintenanceCount] = useState<any>()
  const getClientFilter :any = localStorage.getItem('clientFilter')
  const clientFilter = JSON.parse(getClientFilter)

  const [clientOptions, setClientOptions] = useState<any>([])
  const [clientDropdownStatus, setClienteDropdownStatus] = useState<any>(clientFilter ?? [])

  const [isLoading, setIsLoading] = useState<any>(false)
  const [clientTableData, setClientTableData] = useState<any>([])

  const [checkedValues, setCheckedValues] = useState<any>([])
  const [totalClient,setTotalClient] = useState<any>()
  let totalSelected: any = 0

  useEffect(() => {
    if(pathname === '/clients'){
      localStorage.setItem('clientFilter',JSON.stringify(clientDropdownStatus))
    }
  },[clientDropdownStatus])


  const goToMiantenance = (v: any) => {
    const workspaceId = JSON.stringify(v?.workspaceId)
    localStorage?.setItem('workspaceId',workspaceId)
    let clientDetail : any = `${v?.firstName} ${v?.lastName} ${v?.companyName}`
    navigate(`/client/${v?._id}`,{state:{key:'clients', name: clientDetail , workspaceId:v?.workspaceId}})
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)

  }

  const getAllCount = () => {
    ApiGet('vendor/maintenance_service_request/get_all_count')
      .then((res) => {
        setMaintenanceCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err?.message))
  }


  const getClientDropdownStatus = async () => {
    await ApiGet(`vendor/corporate`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.firstName} ${val?.lastName} ${val?.companyName}`,
            value: `${val?.workspaceId}`,
          }
        })
        setTotalClient(res?.data?.data?.length)
        setClientOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  
  // get Maintenance Request Table Data
  const getClientTableData = async () => {
    setIsLoading(true)
    setClientTableData([])

    let clientIds: any = []
    clientDropdownStatus?.map((v: any, ind: any) => {
      clientIds[ind] = v?.value
    })

    let body = {
      page: page,
      limit: limit,
      corporateWorkspaceIds:clientIds
    }

    await ApiPost('vendor/corporate/get', body)
      .then((res) => {
        // console.log(res.data.data.corporate_data)
        let values: any = []
        for (let i = 0; i < res?.data?.data?.corporate_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setClientTableData(res?.data?.data?.corporate_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteRequests = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = clientTableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    let url: any = `vendor/maintenance_service_request/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
      
          getClientTableData()
          getAllCount()
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }


  useEffect(() => {
    getClientDropdownStatus()
    getAllCount()
  }, [])


  useEffect(() => {
    getClientTableData()
   
  }, [
    page,
    clientDropdownStatus
  ])
 

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        {' '}
        {/* container-xxl */}
        <div className='row'>
          <div className='col-10'>
            <h1 className='page-heading  m-0'>
              <b>Clients</b>
            </h1>
          </div>
          <div className='col-2 d-flex justify-content-between'>
            <h4 className='m-0'>
              <b>Total Clients </b>
            </h4>
            <b><span>{totalClient}</span></b>
          </div>
        </div>

        <div className='row'>
        <div className='col-12 d-flex'>
            <h4 className='m-0 me-5'>
              <b>Total Requests </b>
            </h4>
            <b><span>{maintenanceCount?.maintenanceServiceRequestCount}</span></b>
          </div>
        </div>

        <div className='row'>
            <div className='col-12'>
               <div  className='d-flex flex-lg-wrap flex-xl-nowrap' style={{gap:'20px'}}>
                <div className='customCard py-5  px-7' style={{width: '18rem'}}>
                    <div className='d-flex justify-content-between'>
                        <h4 className='text-gray-500'>Total Jobs</h4>
                        <h4 className='fw-bold'>{maintenanceCount?.maintenanceServiceJobCount}</h4>
                    </div>
                    <div className='customSeparator total-bar'></div>
                </div>
                <div className='customCard py-5  px-7 ' style={{width: '18rem'}}>
                    <div className='d-flex justify-content-between'>
                        <h4 className='text-gray-500'>Not Started</h4>
                        <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestJobNotStartedCount}</h4>
                    </div>
                    <div className='customSeparator booked-bar'></div>
                </div>
                <div className='customCard py-5  px-7 ' style={{width: '18rem'}}>
                    <div className='d-flex justify-content-between'>
                        <h4 className='text-gray-500'>In-Progress</h4>
                        <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestJobInProgressCount}</h4>
                    </div>
                    <div className='customSeparator expiring-bar'></div>
                </div>
                <div className='customCard py-5  px-7 ' style={{width: '18rem'}}>
                    <div className='d-flex justify-content-between'>
                        <h4 className='text-gray-500'>Complete</h4>
                        <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestJobCompletedCount}</h4>
                    </div>
                    <div className='customSeparator active-bar'></div>
                </div>
                <div className='customCard py-5  px-7 ' style={{width: '18rem'}}>
                    <div className='d-flex justify-content-between'>
                        <h4 className='text-gray-500'>Cancelled</h4>
                        <h4 className='fw-bold'>{maintenanceCount?.maintenanceRequestJobCancelledCount}</h4>
                    </div>
                    <div className='customSeparator expired-bar '></div>
                </div>
               </div>
            </div>
          <div className='row py-5 gy-3 mx-0 mt-5' style={{ gap: '0px' }}>
            <div className={`d-flex align-items-center  px-0 col-12`} >
              <div className='xyz me-5 pe-3'>
                <label className='head-text' style={{ fontWeight: '600' }}>
                  {' '}
                  Search{' '}
                </label>
              </div>
         

              <div className='test property me-4 tst'>
                <div
                  className={`multi-select-container ${clientDropdownStatus.length === 0 ? 'no-value-custom-style client' : 'has-value-custom-style'
                    }`}
                >
                  <MultiSelect
                    options={clientOptions}
                    value={clientDropdownStatus}
                    onChange={setClienteDropdownStatus}
                    labelledBy='Client'
                    overrideStrings={{
                      allItemsAreSelected: 'Client', // Custom text for when all items are selected
                    }}
                  />
                </div>
              </div>
            </div>
            </div>
          </div>

        </div>

        <div className='row'>
          <div className='col-12'>
            
          <div className='card card-flush py-5  px-7'>
                <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                  <div className='table-responsive'>
                    <table
                      className='table align-middle table-row-dashed fs-8 gy-5 '
                      id='kt_ecommerce_sales_table'
                    >
                      <thead className='fs-7'>
                        <th className='text-start min-w-150px ps-0'>Client</th>
                        <th className='text-center min-w-75px ps-0'>Requests</th>
                        <th className='text-center min-w-100px ps-0'>Jobs</th>
                        <th className='text-center min-w-100px ps-0'>Not Started</th>
                        <th className='text-center min-w-100px ps-0'>In-Progress</th>
                        <th className='text-center min-w-100px ps-0'>Complete</th>
                        <th className='text-center min-w-100px ps-0'>Cancelled</th>
                        <th className='text-start min-w-75px ps-0 '>Rating</th>
                      </thead>
                      <tbody className='fw-semibold text-gray-600'>
                        {clientTableData?.length > 0 ? (
                          clientTableData?.map((requestTabItem: any, i: any) => {
                            return (
                              <tr>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {[requestTabItem?.firstName,requestTabItem?.lastName,requestTabItem?.companyName]?.every((value) => value !== undefined) ? `${requestTabItem?.firstName} ${requestTabItem?.lastName} ${requestTabItem?.companyName}` : '-'}
                                </td>

                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                               {requestTabItem?.totalServiceRequestCount != undefined ? requestTabItem?.totalServiceRequestCount : '-'}
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                    {' '}
                                  <div className='status approve-st mx-auto'>
                                    {requestTabItem?.totalJobCount != undefined ? requestTabItem?.totalJobCount : '-'}
                                  </div>
                                  

                                </td>
                               
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                    {' '}
                                  <div className='status draft-st mx-auto'>
                                    {requestTabItem?.totalNotStartedJobCount != undefined ? requestTabItem?.totalNotStartedJobCount : '-'}
                                  </div>
                                  

                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status pending-approval-st mx-auto'>
                                    {requestTabItem?.totalNotInProgressCount != undefined ? requestTabItem?.totalNotInProgressCount : '-'}
                                  </div>

                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                    {' '}
                                  <div className='status tenant-approve-st mx-auto'>
                                    {requestTabItem?.totalCompletedCount != undefined ? requestTabItem?.totalCompletedCount : '-'}
                                  </div>
                                  

                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status cancelled-st mx-auto'>
                                    {requestTabItem?.totalCancelledCount != undefined ? requestTabItem?.totalCancelledCount : '-'}
                                  </div>
                                  

                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0 min-w-75px'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}

                                  <div className='d-flex align-items-center '>
                                    <img src={starYellow} height={18} width={18} className='me-1' />
                                    {requestTabItem?.rating != undefined ? requestTabItem?.rating : '-'}
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* pagination start*/}
                  <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='row mt-5'>
                      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='kt_ecommerce_sales_table_paginate'
                        >
                          <ReactPaginate
                            breakLabel='...'
                            nextLabel='>'
                            pageClassName='paginate_button page-item +'
                            pageLinkClassName='page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            previousClassName='paginate_button page-item previous'
                            nextClassName='paginate_button page-item next'
                            previousLinkClassName='page-link'
                            nextLinkClassName='page-link'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageLimit}
                            forcePage={page - 1}
                            previousLabel='<'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* pagination end*/}
                </div>
              </div>
        </div>
      </div>  
    </>

  )
}

export default Clients
