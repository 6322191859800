import moment from "moment";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  Bucket,
} from "../../apiCommon/helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../apiCommon/helpers/Toast";
import queryString from "query-string";
import MediaPopUp from "./MediaPopUp";
import SignaturePopup from "./SignaturePopUp";
import Swal from "sweetalert2";

import crossbtn from "../../img/cross-button.png";
import sign from "../../img/sign.png";

import approve from "../../img/approve.png";
import approved from "../../img/accept.png";
import rejected from "../../img/rejected.png";

import WrittenSignaturePopup from "./WrittenSignaturePopup";
import "./style.scss";

const Tempate = () => {
  const [showMedia, setShowMedia] = React.useState<any>(false);
  const [signatureModal, setSignatureModal] = React.useState<boolean>(false);
  const [writtenSignatureModal, setWrittenSignatureModal] =
    React.useState<boolean>(false);

  const [listData, setListData] = React.useState<any>();
  const [uploadedSign, setUploadedSign] = React.useState<any>("");
  const [checkType, setCheckType] = React.useState<any>("");
  const [idToSend, setIdToSend] = React.useState<any>(
    window.location.pathname?.split("/")[4]
  );

  const [requestData, setRequestData] = React.useState<any>();
  const [approvalRequest, setApprovalRequest] = React.useState<any>();
  const [propertyList, setPropertyList] = React.useState<any>();
  const [page, setPage] = React.useState<any>(1);
  const [formData, setFormData] = React.useState<any>({});
  const [vendorData, setVendorData] = React.useState<any>();
  const [vendorFinalId, setVendorFinalId] = React.useState<any>();
  const location = useLocation();
  const urlData: any = queryString.parse(location.search);
  const [jobId, setJobId] = React.useState<any>();
  const [serviceData, setServicedata] = React.useState<any>();
  const [mediaPopup, setMediaPopup] = React.useState<any>(false);
  const [mediaPop, setMediaPop] = React.useState<any>();
  const [unitNo, setUnitNo] = React.useState<any>();

  var today: any = new Date();
  const currentTimee = new Date();
  const currentHours = currentTimee.getHours();
  const currentMinutes = currentTimee.getMinutes();

  localStorage.setItem("token", urlData?.token);

  const getServiceData = async () => {
    await ApiGet(`vendor/service_request/${urlData?.serviceRequestId}`)
      .then((res) => {
        setRequestData(res?.data?.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getApprovalServiceRequest = async () => {
    const body = {
      serviceRequestId: urlData?.serviceRequestId,
    };
    await ApiPost(`vendor/approval_request/get`, body)
      .then((res) => {
        setApprovalRequest(res?.data?.data?.serviceApprovalRequest);
        console.info(res?.data?.data?.serviceApprovalRequest);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const serviceRequestData = async () => {
    const body = {
      page: page,
      limit: 10,
      serviceRequestId: urlData?.serviceRequestId,
    };
    await ApiPost(`vendor/service_job/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.service_job_data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getServiceJob = async () => {
    const body = {
      serviceRequestId: urlData?.serviceRequestId,
    };
    await ApiPost(`vendor/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getVendorData = async () => {
    await ApiGet("vendor")
      .then((res) => {
        setVendorData(res?.data?.data);
      })
      .catch((err) => ErrorToast(err.message));
  };
  React.useEffect(() => {
    getServiceData();
    getApprovalServiceRequest();
    serviceRequestData();
    getVendorData();
    getServiceJob();
  }, [urlData?.serviceRequestId]);

  const openModal = async (ids: any, unitN: any) => {
    setUnitNo(unitN);

    await ApiGet(`vendor/service_job/${ids}`)
      .then((res) => {
        setMediaPop(res.data.data[0]);
        setJobId(ids);
        setShowMedia(true);
      })
      .catch((err) => ErrorToast(err.message));
  };

  const approverequest = async () => {
    var eTemp, writtenTemp;
    if (checkType === 1) {
      eTemp = uploadedSign;
      writtenTemp = "";
    } else {
      eTemp = "";
      writtenTemp = uploadedSign;
    }
    // setSignatureModal(true);
    const id = approvalRequest?.filter(
      (i: any) => i?.type === 2 || i?.type === 3
    );

    let idToSend: any;
    id?.map((i: any) => {
      if (i?.vendor[0]?._id === vendorData?._id) {
        idToSend = i._id;
        console.log(idToSend);
      }
    });

    const body = {
      id: idToSend,
      approvalStatus: 2,
      // vendorStatus: 2,
      comment: "TEST",
      eSignature: eTemp,
      writtenSignature: writtenTemp,
    };

    await ApiPut("vendor/approval_request", body)
      .then((res) => {
        SuccessToast("Request has been accepted successfully!!!");
        getServiceData();
        getApprovalServiceRequest();
        // handleClose()
      })
      .catch((err) => ErrorToast(err.message));
  };

  let data: any;

  // Reject request
  const rejectRequest = async () => {
    const id = approvalRequest?.filter(
      (i: any) => i?.type === 2 || i?.type === 3
    );
    // const vendorId = id?.map((i: any) => i?.vendor[0]?._id === vendorData?._id);
    // if (approvalRequest && vendorId[0] === true) {
    //   data = approvalRequest[1]?._id;
    // }

    let idToSend: any;
    id?.map((i: any) => {
      if (i?.vendor[0]?._id === vendorData?._id) {
        idToSend = i._id;
        console.log(idToSend);
      }
    });

    const body = {
      id: idToSend,
      approvalStatus: 3,
      // vendorStatus: 3,
      comment: "TEST",
      writtenSignature: "",
    };

    await ApiPut("vendor/approval_request", body)
      .then((res) => {
        SuccessToast("Request has been rejected successfully!!!");
        getServiceData();
        getApprovalServiceRequest();
      })
      .catch((err) => ErrorToast(err.message));
  };

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n);
    let newVal: any = temp.toLocaleTimeString();
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60;
    let newValHours = parseInt(h1);
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5));
    //  console.log(newValMins)

    var num: number = newValHours + newValMins;
    // console.log(num)

    var hours = num / 60;
    var rhours = Math.floor(hours);
    var fhour: any = "" + rhours;
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    var fminutes: any = "" + rminutes;
    if (rhours < 10) fhour = "0" + rhours;
    if (rminutes < 10) fminutes = "0" + rminutes;
    let x: any = fhour + ":" + fminutes;
    return x;
  }

  const updateSignature = (newValue: string, checkType: number) => {
    setUploadedSign(newValue);
    setCheckType(checkType);
    setWrittenSignatureModal(false);
    setSignatureModal(false);
  };

  // To close Both Modals at a time
  const closeBoth = () => {
    setWrittenSignatureModal(false);
    setSignatureModal(false);
  };
  return (
    <div
      className="app-main facilitie_create flex-column flex-row-fluid"
      id="kt_app_main"
    >
      <div id="" className="app-container container-xxl pt-0 mt-0">
        <Row className="mb-5">
          <div className="col-md-6"></div>
          {/* 6 */}
          {requestData?.vendorStatus === 1 ? (
            <div className="col-md-6 d-flex justify-content-end align-items-center approval-flow">
              <div>
                <button
                  className="btn btn-sm fw-bold btn-primary btn-green mx-3"
                  onClick={() => approverequest()}
                >
                  <img
                    src={approve}
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "7px",
                      marginLeft: "-10px",
                    }}
                  />
                  Accept
                </button>
              </div>
              <div>
                <button
                  className="btn btn-sm fw-bold  ms-3 text-white"
                  style={{ backgroundColor: "rgb(213, 70, 69)" }}
                  onClick={() =>
                    Swal.fire({
                      html: '<p class="text-center m-0" style="font-size: 16px"> You are about to reject the entire request, do you wish to proceed?</p>',
                      showConfirmButton: true,
                      confirmButtonColor: "#007a59",
                      confirmButtonText: "Confirm",
                      showCancelButton: true,
                      cancelButtonText: "Cancel",
                    }).then((res) => {
                      if (res.isConfirmed) {
                        rejectRequest();
                      }
                    })
                  }
                >
                  <img
                    src={crossbtn}
                    style={{
                      height: "22px",
                      width: "22px",
                      marginRight: "7px",
                      marginLeft: "-10px",
                    }}
                  />
                  Reject
                </button>
              </div>
            </div>
          ) : // 7
          requestData?.vendorStatus === 2 ? (
            <>
              <div className="col-md-6 d-flex justify-content-end align-items-center approval-flow">
                <div>
                  <button
                    className="btn btn-sm fw-bold btn-primary btn-green ms-3"
                    style={{ cursor: "auto" }}
                  >
                    <img
                      src={approved}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "5px",
                        marginLeft: "-10px",
                      }}
                    />
                    Accepted
                  </button>
                </div>
              </div>
            </>
          ) : // 4
          requestData?.vendorStatus === 3 ? (
            <>
              <div className="col-md-6 d-flex justify-content-end align-items-center approval-flow">
                <div>
                  <button
                    className="btn btn-sm fw-bold btn-primary btn-green ms-3"
                    style={{ cursor: "auto" }}
                  >
                    <img
                      src={rejected}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "7px",
                        marginLeft: "-10px",
                      }}
                    />
                    Rejected
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Row>

        {/* <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                    Request Log
                                </a>
                            </li>
                        </ul>
                    </div> */}
        {/* <div className="card card-flush app-container py-5">
        
          <div className="d-flex justify-content-between">
            <div className="col-md-6">
              <h1
                className="page-heading d-flex fw-bold mt-3 flex-column justify-content-center mb-0"
                style={{ color: "#007a59" }}
              >
                Approval Workflow
              </h1>
            </div>
          </div>
          <div className="pt-0 table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th className="text-center">User</th>
                  <th className="text-center min-w-100px">Updated</th>
                  <th className="text-center min-w-100px">Status</th>
                  <th className="text-center min-w-100px">Signature</th>
                  <th className="text-center min-w-100px">Comment</th>
                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {approvalRequest?.map((f: any, i: any) => {
                  return (
                    <tr>
                      <td className="text-center">
                        {f?.type === 1 || f?.type === 0
                          ? f?.corporate[0]?.firstName +
                            " " +
                            f?.corporate[0]?.lastName
                          : f?.type === 2 || f?.type === 3
                          ? f?.vendor[0]?.firstName +
                            " " +
                            f?.vendor[0]?.lastName
                          : ""}
                      </td>
                      <td className="text-center">
                        {moment(f?.updatedAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="text-center">
                        {f?.approvalStatus === 2
                          ? "Approved"
                          : f?.approvalStatus === 3
                          ? "Rejected"
                          : f?.approvalStatus === 1
                          ? "Requested"
                          : f?.approvalStatus === 0
                          ? "Pending"
                          : "Send Back"}
                      </td>
                     
                      {f?.corporate[0] ? (
                        <td className="approval-flow text-center">
                          {f?.eSignature && (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={`${Bucket}${f?.eSignature}`}
                            />
                          )}
                          {f?.writtenSignature && (
                            <a
                              className="btn btn-sm fw-bold btn-primary btn-green mx-3"
                              href={`${Bucket}${f?.writtenSignature}`}
                              target="_blank"
                            >
                              Signed Document
                            </a>
                          )}
                        </td>
                      ) : (
                        <>
                          <td className="approval-flow text-center">
                            {requestData?.requestStatus !== 7 ? (
                              <>
                                {f?.eSignature !== null ||
                                f?.writtenSignature !== null ||
                                uploadedSign !== "" ? (
                                  <span>Signed</span>
                                ) : (
                                  ""
                                )}
                                {f?.eSignature !== null ||
                                f?.writtenSignature !== null ||
                                uploadedSign !== "" ? (
                                  <button
                                    onClick={() => {
                                      setIdToSend(f?._id);
                                      setSignatureModal(true);
                                      setWrittenSignatureModal(true);
                                    }}
                                    className="btn btn-sm fw-bold btn-primary btn-green p-auto hover-none ms-3"
                                  >
                                    <img
                                      src={sign}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginRight: "7px",
                                        marginLeft: "-10px",
                                      }}
                                    />
                                    Update Signature
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => {
                                      setIdToSend(f?._id);
                                      setSignatureModal(true);
                                      setWrittenSignatureModal(true);
                                    }}
                                    className="btn btn-sm fw-bold btn-primary btn-green p-auto hover-none ms-3"
                                  >
                                    <img
                                      src={sign}
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginRight: "7px",
                                        marginLeft: "-10px",
                                      }}
                                    />
                                    Add Signature
                                  </button>
                                )}
                              </>
                            ) : (
                              <>
                                {f?.eSignature && (
                                  <img
                                    style={{ height: "35px", width: "35px" }}
                                    src={`${Bucket}${f?.eSignature}`}
                                  />
                                )}
                                {f?.writtenSignature && (
                                  <a
                                    className="btn btn-sm fw-bold btn-primary btn-green mx-3"
                                    href={`${Bucket}${f?.writtenSignature}`}
                                    target="_blank"
                                  >
                                    Signed Document
                                  </a>
                                )}
                              </>
                            )}

                           
                          </td>
                          <td className="show-line-sign text-center">
                            ________________
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div> */}

        {/* <div className="card card-flush app-container py-5"> */}
        {/* Request Summary */}
        <div className="card app-container  mt-5  p-5">
          <Row>
            <Col md={12}>
              <h1 className="top green_color mb-2">Request Summary</h1>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <h6 className="text-muted">
                Request ID
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {requestData?.id}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className="text-muted">
                Request Source
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {requestData?.requestType === 2 ? "Internal" : "External"}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className="text-muted">
                Assigned to
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {requestData?.staff[0]?.firstName}{" "}
                  {requestData?.staff[0]?.lastName}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Service
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {requestData?.main_service[0]?.name} -{" "}
                  {requestData?.sub_service[0]?.name}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Request Urgency
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {requestData?.requestUrgency === 0 ? "Standard" : "Emergency"}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Developments
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {serviceData?.total_building + serviceData?.total_community}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Total Units
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {serviceData?.total_units}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Total Jobs
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                  {propertyList?.length}
                </span>
              </h6>
            </Col>
            <Col md={4} className="mt-1">
              <h6 className="text-muted">
                Comments for Vendor
                <span className="mx-1 pe-4" style={{ color: "black" }}>
                 {requestData?.vendorComment}
                </span>
              </h6>
            </Col>
          </Row>

          {/* <div className="grid-out-diff-gap container-xxl px-0"> */}
          {/* <div className="p-2 px-0 side ">
           
              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Request ID
                <div className="mx-1 value_black">{requestData?.id}</div>
              </h6>

              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Service
                <div className="mx-1 value_black">
                  {" "}
                  {requestData?.main_service[0]?.name} -{" "}
                  {requestData?.sub_service[0]?.name}
                </div>
              </h6>

              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Assigned to
                <div className="mx-1 value_black">
                  {" "}
                  {requestData?.staff[0]?.firstName}{" "}
                  {requestData?.staff[0]?.lastName}
                </div>
              </h6>

              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Developments
                <div className='mx-1 value_black'>   {serviceData?.total_building + serviceData?.total_community}</div>
              </h6>
              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Total Units
                <div className='mx-1 value_black'>   {serviceData?.total_units}</div>
              </h6>
              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 3fr" }}
              >
                Total Jobs
                <div className="mx-1 value_black"> {propertyList?.length} </div>
              </h6>
            </div> */}
          {/* <div className="p-2 px-0 side ">
            
              <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 2fr" }}
              >
                Request Source
                <div className="mx-1 value_black">
                  {" "}
                  {requestData?.requestType === 2 ? "Internal" : "External"}
                </div>
              </h6> */}

          {/* <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 2fr" }}
              >
                Request Urgency
                <div className="mx-1 value_black">
                  {" "}
                  {requestData?.requestUrgency === 0 ? "Standard" : "Emergency"}
                </div>
              </h6> */}

          {/* <h6
                className="mx-0 text-muted"
                style={{ gridTemplateColumns: "1fr 2fr" }}
              >
                Preferred Schedules for Service
                <div className="mx-1 value_black">
                  {" "}
                  {requestData?.schedule?.[0]?.preferScheduleDate &&
                    moment(
                      requestData?.schedule?.[0]?.preferScheduleDate
                    ).format("DD/MM/YYYY") + " - "}
                  {requestData?.schedule?.[0]?.preferScheduleTime &&
                    timeConvert(
                      requestData?.schedule?.[0]?.preferScheduleTime
                    )}{" "}
                </div>
              </h6> */}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* <h3 className="fw-bold"> Request Details</h3> */}
        {/* <div className="d-flex">
            <p className="me-5 min-w-150px">
              <b>Request ID: {requestData?.id} </b>
            </p>
            <p className="me-5 min-w-150px">
              <b>Request Source: External - Vendor List </b>
            </p>
          </div> */}
        {/* <div className="my-5 d-flex"> */}
        {/* <Col lg={6}>
              <h3 className="page-heading d-flex text-darkfs-3 flex-column justify-content-center mb-0 mb-5">
                Request Summary
              </h3>
              <div className="select-box p-5 d-inline-block">
                <div className="d-flex">
                  <div className="me-10">
                    <p className="m-0">
                      Service:{" "}
                      <b>
                        <p className="m-0">
                          Service: {requestData?.main_service[0]?.name} -{" "}
                          {requestData?.sub_service[0]?.name}
                        </p>
                      </b>
                    </p>
                    <p className="m-0">
                      Assigned To: <b>{requestData?.staff[0]?.name}</b>
                    </p>
                    <p className="m-0">
                      Total Buildings/Communities:
                      <b>{requestData?.totalProperties}</b>{" "}
                    </p>
                    <p className="m-0">
                      Total Units:<b>{requestData?.totalProperties}</b>{" "}
                    </p>
                    <p className="m-0">
                      Total Jobs:<b>{requestData?.totalProperties}</b>{" "}
                    </p>
                  </div>
                  <div className="me-10">
                    <p className="m-0 d-flex align-items-center">
                      Request Urgency:{" "}
                      <b>
                        <span
                          style={{
                            marginLeft: "5px",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            background: "green",
                            display: "inline-block",
                          }}
                        ></span>{" "}
                        Standard
                      </b>
                    </p>
                    <p className="m-0">Preferred Schedules For Service </p>
                    <p className='m-0'><b>1. {requestData?.schedule[0]?.preferScheduleDate && moment(requestData?.schedule[0]?.preferScheduleDate).format('DD/MM/YYYY')} - {timeConvert(requestData?.schedule[0]?.preferScheduleTime)} </b></p>
                    <p className='m-0'><b>2. DD.MM.YYYY - HH:MM AM/PM</b></p>
                  </div>
                </div>
              </div>
            </Col> */}
        {/* <Col>
              <div className="mb-3">
                <h6 className="text-muted m-0 mr-2 min-w-100px mb-2">
                  Request Details (For Selected Vendor)
                </h6>
                <textarea
                  className="form-control form-control-solid mb-8"
                  rows={3}
                  name="comment"
                  placeholder="Type or paste emails here"
                ></textarea>
              </div>
            </Col> */}
        {/* </div> */}
        <div className="card app-container  mt-5  p-5">
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <h1 className="page-heading d-flex fw-bold m-0 me-5 flex-column justify-content-center green_color">
              Properties
            </h1>
          </div>
          <div className="pt-0 table-responsive">
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_sales_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th className="text-center min-w-100px">Job ID</th>
                  <th className="text-center min-w-100px">Development</th>
                  <th className="text-center min-w-100px">Floor/Cluster</th>
                  <th className="text-center min-w-100px">Unit</th>
                  <th className="text-center min-w-100px">Unit Type </th>
                  <th className="text-center min-w-100px">Bedrooms </th>
                  <th className="text-center min-w-100px">Job Schedule </th>
                  <th className="text-center min-w-100px"></th>
                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {propertyList?.map((f: any, i: any) => {
                  return (
                    <tr>
                      <td className="text-center">{f?.id}</td>
                      <td className="text-center">
                        {f?.community.length !== 0 ? "Community" : "Building"}
                      </td>
                      <td className="text-center">
                        {f?.community.length !== 0 ? "Cluster" : "Floor"}
                      </td>
                      <td className="text-center">{f?.unit[0]?.unitNo}</td>
                      <td className="text-center">{f?.unit[0]?.unitType}</td>
                      <td className="text-center">{f?.unit[0]?.bedrooms}</td>
                      <td className="text-center">
                        {" "}
                        {f?.schedule?.[0]?.date
                          ? moment(f?.schedule?.[0]?.date).format("DD.MM.YYYY")
                          : null}{" "}
                        {f?.schedule?.[0]?.startTime
                          ? timeConvert(f?.schedule?.[0]?.startTime) + " - "
                          : null}
                        {f?.schedule?.[0]?.endTime
                          ? timeConvert(f?.schedule?.[0]?.endTime)
                          : null}{" "}
                        {f?.schedule?.[0]?.date && (
                          <>
                            {/* Same Day */}
                            {new Date(f?.schedule?.[0]?.date).getFullYear() ===
                              today.getFullYear() &&
                            new Date(f?.schedule?.[0]?.date).getMonth() ===
                              today.getMonth() &&
                            new Date(f?.schedule?.[0]?.date).getDate() ===
                              today.getDate() ? (
                              <>
                                {currentHours >
                                parseInt(
                                  timeConvert(
                                    f?.schedule?.[0]?.startTime
                                  ).split(":")[0]
                                ) ? (
                                  <p style={{ color: "var(--kt-danger)" }}>
                                    {" "}
                                    Schedule has Expired
                                  </p>
                                ) : (
                                  <>
                                    {parseInt(
                                      timeConvert(
                                        f?.schedule?.[0]?.startTime
                                      ).split(":")[0]
                                    ) -
                                      currentHours <=
                                    6 ? (
                                      <p style={{ color: "var(--kt-danger)" }}>
                                        {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                        Schedule is Approaching
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </>
                            ) : // next Day
                            new Date(f?.schedule?.[0]?.date) > today ? (
                              <>
                                {new Date(f?.schedule?.[0]?.date).getDate() ===
                                today.getDate() + 1 ? (
                                  <>
                                    {24 -
                                      currentHours +
                                      parseInt(
                                        timeConvert(
                                          f?.schedule?.[0]?.startTime
                                        ).split(":")[0]
                                      ) <=
                                      6 && (
                                      <p style={{ color: "var(--kt-danger)" }}>
                                        Schedule is Approaching
                                      </p>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                <></>
                              </>
                            ) : (
                              <p style={{ color: "var(--kt-danger)" }}>
                                Schedule has expired
                              </p>
                            )}
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm fw-bold btn-primary btn-green hover-none"
                          onClick={() => openModal(f?._id, f?.unit[0]?.unitNo)}
                        >
                          Media & Comments
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* </div> */}
        <MediaPopUp
          unitNo={unitNo}
          show={showMedia}
          handleClose={() => {
            setShowMedia(false);
          }}
          propertyList={propertyList}
          id={jobId}
          mediaPop={mediaPop}
        />
        {/* E-signature Popup */}
        <SignaturePopup
          show={signatureModal}
          handleClose={() => {
            setSignatureModal(false);
          }}
          setFormData={setFormData}
          formData={approvalRequest}
          vendorData={vendorData}
          getApprovalServiceRequest={getApprovalServiceRequest}
          listData={listData}
          updateSignature={updateSignature}
          closeBoth={closeBoth}
        />

        {/* Written Signature Popup */}
        <WrittenSignaturePopup
          show={writtenSignatureModal}
          handleClose={() => {
            setWrittenSignatureModal(false);
          }}
          setFormData={setFormData}
          formData={approvalRequest?.map((f: any) => f)}
          getApprovalServiceRequest={getApprovalServiceRequest}
          listData={listData}
          updateSignature={updateSignature}
          closeBoth={closeBoth}
        />
      </div>
    </div>
  );
};

export default Tempate;
